import { ThemeOptions, createTheme, responsiveFontSizes } from '@mui/material';
import 'core/types/theme';

// Default theme
const defaultTheme = createTheme();

// Create new theme
let theme = createTheme({
	palette: {
		primary: {
			main: '#0080A3',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#5235E3',
		},
		info: {
			main: '#00B0E1',
			contrastText: '#FFFFFF',
		},
		success: {
			main: '#00A34E',
		},
		warning: {
			main: '#E86D3C',
		},
		error: {
			main: '#B90011',
		},
	},
	typography: {
		htmlFontSize: 16,
		allVariants: {
			color: '#212121',
		},
		h1: {
			fontSize: '3.6rem',
		},
		h2: {
			fontSize: '2.5rem',
		},
		h3: {
			fontSize: '1.75rem',
			color: defaultTheme.palette.grey[700],
		},
		h4: {
			fontSize: '1.325rem',
			fontWeight: 500,
			color: defaultTheme.palette.grey[700],
		},
		h5: {
			fontSize: '1.25rem',
			color: defaultTheme.palette.grey[700],
		},
		h6: {
			fontSize: '0.925rem',
			fontStyle: 'italic',
			color: defaultTheme.palette.grey[600],
		},
		subtitle2: {
			fontSize: '0.925rem',
			color: defaultTheme.palette.grey[600],
		},
		subtitle1: {
			color: defaultTheme.palette.grey[700],
		},
		button: {
			textTransform: 'capitalize',
		},
	},
	breakpoints: {
		values: {
			...defaultTheme.breakpoints.values, // Defaults
			xl: 1700,
		},
	},
});

// Component overrides
theme = createTheme(theme, {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						color: undefined,
						backgroundColor: undefined,
						opacity: 0.4,
					},
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: theme.spacing(3),
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'.Mui-error & svg': {
						color: theme.palette.error.main,
					},
				},
			},
		},
		MuiDataGrid: {
			defaultProps: {
				rowHeight: 50,
				columnHeaderHeight: 50,
				pageSizeOptions: [10, 20, 50],
				initialState: {
					pagination: { paginationModel: { pageSize: 10 } },
				},
				getRowClassName: ({ indexRelativeToCurrentPage }: { indexRelativeToCurrentPage: number }) =>
					indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd',
				pagination: true,
				autoHeight: true,
				disableColumnMenu: true,
			},
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'filled',
				fullWidth: true,
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					'& .MuiFormControlLabel-label': {
						color: theme.typography.subtitle2.color,
					},
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: theme.typography.subtitle2.color,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: theme.typography.subtitle2.color,
				},
				asterisk: {
					color: theme.palette.error.main,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					'&.Mui-error': {
						borderColor: theme.palette.error.main,
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&:hover:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.palette.primary.main,
					},
				},
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 2,
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					color: theme.typography.subtitle2.color,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					color: theme.palette.primary.main,
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				arrow: true,
				placement: 'top',
			},
			styleOverrides: {
				tooltip: {
					padding: theme.spacing(1.5),
					backgroundColor: theme.palette.grey[700],
					fontSize: theme.typography.body2.fontSize,
					textAlign: 'center',
				},
			},
		},
	},
	navbar: {
		mode: 'vertical',
		sidebar: {
			initialState: 'open',
			backgroundColor: '#2A414B',
			color: '#FFFFFF',
		},
		backgroundColor: {
			main: '#2A414B',
			hover: '#FFFFFF',
		},
		color: {
			main: '#FFFFFF',
			inverted: '#2A414B',
		},
	},
} as ThemeOptions);

// Enable responsive fonts
theme = responsiveFontSizes(theme);

// Export the theme
const finalTheme = theme;
export default finalTheme;
