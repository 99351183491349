import { DialogContentText, Grid, InputLabel, Typography } from '@mui/material';
import Dialog, { DialogProps } from 'core/components/Dialog';
import Document from 'modules/documents/types/Document';
import { useTranslation } from 'react-i18next';

export interface DeleteDocumentDialogProps extends Pick<DialogProps, 'isOpen' | 'setIsOpen' | 'onConfirm'> {
	document: Document | null;
}

export default function DeleteDocumentDialog({ document, isOpen, setIsOpen, onConfirm }: DeleteDocumentDialogProps) {
	const { t } = useTranslation('documents');
	if (!document) return null;

	return (
		<Dialog
			title={t('dialogs.delete.title')}
			severity="error"
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			onConfirm={onConfirm}
		>
			<DialogContentText>{t('dialogs.delete.body')}</DialogContentText>
			<Grid container columnSpacing={2} mt={2}>
				<Grid item xs={3}>
					<InputLabel sx={{ mb: 1 }}>{t('dialogs.delete.type')}</InputLabel>
					<Typography variant="body2">{t(`types.${document.type.code}`)}</Typography>
				</Grid>
				<Grid item xs>
					<InputLabel sx={{ mb: 1 }}>{t('dialogs.delete.name')}</InputLabel>
					<Typography variant="body2">{document.name}</Typography>
				</Grid>
			</Grid>
		</Dialog>
	);
}
