import { CanAccess } from 'core/components';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import SupplementPageContainer, {
	SupplementContainerContext,
} from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import AddFleetAddVehicleContainer from 'modules/irp/modules/supplements/modules/add_fleet/components/AddFleetAddVehicleContainer';
import AddFleetSteps from 'modules/irp/modules/supplements/modules/add_fleet/components/AddFleetSteps';
import SupplementBlocker from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { lazy, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Permissions from 'types/Permissions';
import { WeightGroupNew } from 'types/WeightGroup';
import AddFleetDetailsStep from './AddFleetDetailsStep';
import AddFleetPaths from './paths';

const AddFleetJurisdictionsStep = lazy(() => import('./AddFleetJurisdictionsStep'));
const AddFleetWeightGroupsStep = lazy(() => import('./AddFleetWeightGroupsStep'));
const AddFleetVehiclesStep = lazy(() => import('./AddFleetVehiclesStep'));
const AddFleetDocumentsStep = lazy(() => import('./AddFleetDocumentsStep'));
const AddFleetVerifyStep = lazy(() => import('./AddFleetVerifyStep'));
const AddFleetSubmitStep = lazy(() => import('./AddFleetSubmitStep'));

const AddFleetWeightGroup = lazy(() => import('./AddFleetWeightGroup'));

const AddFleetAddVehicleOptions = lazy(() => import('./AddFleetAddVehicleOptions'));
const AddFleetAddVehicleInfo = lazy(() => import('./AddFleetAddVehicleInfo'));
const AddFleetAddVehicleWeightGroup = lazy(() => import('./AddFleetAddVehicleWeightGroup'));
const AddFleetAddVehicleRegistration = lazy(() => import('./AddFleetAddVehicleRegistration'));

// View Vehicle Detail
const VehicleDetailsRoute = lazy(() => import('./AddFleetVehiclesView'));
const VehicleDetailsPlate = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPlate'));
const VehicleDetailsCredentials = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsCredentials'),
);
const VehicleDetailsPurchase = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPurchase'));
const VehicleDetailsDocuments = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsDocuments'));
const VehicleDetailsRegistration = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsRegistration'),
);

function AddFleetIndex() {
	const { supplementKey } = useTypedParams(AddFleetPaths);
	return <Redirect to={AddFleetPaths.Details.buildPath({ supplementKey })} replace />;
}

function AddWeightGroupContainer({ children }: PropsWithChildren) {
	const { supplementKey } = useTypedParams(AddFleetPaths);
	const value = useMemo(
		() => ({ closePath: AddFleetPaths.WeightGroups.buildPath({ supplementKey }) }),
		[supplementKey],
	);

	return <SupplementContainerContext.Provider value={value}>{children}</SupplementContainerContext.Provider>;
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/add_fleet');
	return (
		<SupplementBlocker basePath={AddFleetPaths} title={t('dialogs.close.title', { ns: 'irp/supplements/add_fleet' })}>
			{children}
		</SupplementBlocker>
	);
}

// Route component mappings
const AddFleetRoutes = [
	{
		path: AddFleetPaths.path,
		element: (
			<CanAccess resource={Permissions.IRP.Fleets.resource} action={Actions.CREATE} showError>
				<SupplementPageContainer
					breadcrumbs={['irp/supplements/add_fleet', 'irp/supplements', 'irp/weight_groups', 'irp/vehicles']}
					stepRoutes={[
						AddFleetPaths.Details.path,
						AddFleetPaths.Details.Jurisdictions.path,
						AddFleetPaths.WeightGroups.path,
						AddFleetPaths.Vehicles.path,
						AddFleetPaths.Documentation.path,
						AddFleetPaths.Verify.path,
						AddFleetPaths.Submit.path,
					]}
					steps={<AddFleetSteps />}
				>
					<Outlet />
				</SupplementPageContainer>
			</CanAccess>
		),
		children: [
			// Index route
			{
				path: AddFleetPaths.path,
				index: true,
				element: <AddFleetIndex />,
			},

			// Steps

			// Details
			{
				path: AddFleetPaths.Details.path,
				element: (
					<WithBlocker>
						<Outlet />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('title', { ns: 'irp/supplements/add_fleet' }),
				},
				children: [
					{
						index: true,
						path: AddFleetPaths.Details.path,
						element: <AddFleetDetailsStep />,
					} as Route<typeof AddFleetPaths.Details>,
					{
						path: AddFleetPaths.Details.Jurisdictions.path,
						element: <AddFleetJurisdictionsStep />,
					} as Route<typeof AddFleetPaths.Details.Jurisdictions>,
				],
			} as Route<typeof AddFleetPaths.Details>,

			// Weight Groups
			{
				path: AddFleetPaths.WeightGroups.path,
				element: <Outlet />,
				handle: {
					crumb: ({ t }) => t('title', { ns: 'irp/supplements/add_fleet' }),
				},
				children: [
					{
						index: true,
						path: AddFleetPaths.WeightGroups.path,
						element: (
							<WithBlocker>
								<AddFleetWeightGroupsStep />
							</WithBlocker>
						),
					} as Route<typeof AddFleetPaths.WeightGroups>,

					// Weight Group add/edit
					{
						path: AddFleetPaths.WeightGroups.WeightGroup.path,
						element: (
							<AddWeightGroupContainer>
								<AddFleetWeightGroup />
							</AddWeightGroupContainer>
						),
						handle: {
							crumb: ({ t, params }) =>
								t(`irp/weight_groups:${params.weightGroupKey === WeightGroupNew ? 'add' : 'edit'}.title`),
						},
					} as Route<typeof AddFleetPaths.WeightGroups.WeightGroup>,
				],
			} as Route<typeof AddFleetPaths.WeightGroups>,

			// Vehicles
			{
				path: AddFleetPaths.Vehicles.path,
				element: <Outlet />,
				handle: {
					crumb: ({ t }) => t('title', { ns: 'irp/supplements/add_fleet' }),
				},
				children: [
					{
						index: true,
						element: (
							<WithBlocker>
								<AddFleetVehiclesStep />
							</WithBlocker>
						),
					},

					// Vehicle add/edit
					{
						path: AddFleetPaths.Vehicles.Vehicle.path,
						element: (
							<AddFleetAddVehicleContainer>
								<Outlet />
							</AddFleetAddVehicleContainer>
						),
						handle: {
							crumb: ({ t }) => t('details.title', { ns: 'irp/vehicles' }),
						},
						children: [
							// Index route
							{
								path: '',
								index: true,
								element: <AddFleetAddVehicleOptions />,
							} as Route<typeof AddFleetPaths.Vehicles.Vehicle>,
							{
								path: AddFleetPaths.Vehicles.Vehicle.Info.path,
								element: <AddFleetAddVehicleInfo />,
							},
							{
								path: AddFleetPaths.Vehicles.Vehicle.WeightGroup.path,
								element: <AddFleetAddVehicleWeightGroup />,
							},
							{
								path: AddFleetPaths.Vehicles.Vehicle.Registration.path,
								element: <AddFleetAddVehicleRegistration />,
							} as Route<typeof AddFleetPaths.Vehicles.Vehicle.Registration>,
						],
					} as Route<typeof AddFleetPaths.Vehicles.Vehicle>,

					// Vehicle View
					{
						path: AddFleetPaths.Vehicles.Vehicle.View.path,
						handle: {
							crumb: ({ t }) => t('details.title', { ns: 'irp/vehicles' }),
						},
						element: <VehicleDetailsRoute />,
						children: [
							{
								path: AddFleetPaths.Vehicles.Vehicle.View.path,
								index: true,
								element: <VehicleDetailsPlate />,
							},
							{
								path: AddFleetPaths.Vehicles.Vehicle.View.Credentials.path,
								element: <VehicleDetailsCredentials />,
							},
							{
								path: AddFleetPaths.Vehicles.Vehicle.View.Purchase.path,
								element: <VehicleDetailsPurchase />,
							},
							{
								path: AddFleetPaths.Vehicles.Vehicle.View.Documents.path,
								element: <VehicleDetailsDocuments />,
							},
							{
								path: AddFleetPaths.Vehicles.Vehicle.View.Registration.path,
								element: <VehicleDetailsRegistration />,
							} as Route<typeof AddFleetPaths.Vehicles.Vehicle.View.Registration>,
						],
					} as Route<typeof AddFleetPaths.Vehicles.Vehicle.View>,
				],
			} as Route<typeof AddFleetPaths.Vehicles>,

			// Documentation
			{
				path: AddFleetPaths.Documentation.path,
				element: (
					<WithBlocker>
						<AddFleetDocumentsStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('title', { ns: 'irp/supplements/add_fleet' }),
				},
			} as Route<typeof AddFleetPaths.Documentation>,

			// Verify
			{
				path: AddFleetPaths.Verify.path,
				element: (
					<WithBlocker>
						<AddFleetVerifyStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('title', { ns: 'irp/supplements/add_fleet' }),
				},
			} as Route<typeof AddFleetPaths.Verify>,

			// Submit
			{
				path: AddFleetPaths.Submit.path,
				element: (
					<WithBlocker>
						<AddFleetSubmitStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('title', { ns: 'irp/supplements/add_fleet' }),
				},
			} as Route<typeof AddFleetPaths.Submit>,
		],
	} as Route<typeof AddFleetPaths>,
];

export default AddFleetRoutes;
