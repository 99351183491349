import { Loader } from 'core/components';
import { openToast } from 'core/services/toast';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import RazorPaths from 'modules/razor/paths';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SupplementType } from 'types/Supplement';
import { SupplementTypePaths } from './paths';

export default function SupplementRouter() {
	const { supplement } = useSupplement();
	const navigate = useNavigate();
	const { t } = useTranslation(['irp/supplements']);

	// Redirect based on supplement type
	useEffect(() => {
		if (!supplement) return;
		const { key: supplementKey } = supplement;

		// Make sure the redirect exists
		const url = SupplementTypePaths[supplement.type.code as SupplementType];
		if (!url) {
			openToast({
				id: `redirect-dialog:${supplementKey}`,
				message: t('dialogs.redirect.body', { ns: 'irp/supplements' }),
				severity: 'success',
			});

			navigate(
				`${RazorPaths.Manage.Supplements.buildPath(
					{},
					{
						supplementKeyId: supplementKey,
					},
				)}`,
			);
			return;
		}

		// Redirect to the appropriate supplement route
		navigate(url.buildPath({ supplementKey }), { replace: true });
	}, [supplement, navigate, t]);

	return <Loader sx={{ flex: 1 }} />;
}
