import { Box } from '@mui/material';
import { useAPI, useToast } from 'core/hooks';
import FleetsService from 'modules/irp/modules/fleets/api/FleetsService';
import FleetForm, { FleetFormProps } from 'modules/irp/modules/fleets/components/FleetForm';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { FleetFields } from 'types/Fleet';
import EditFleetPaths from './paths';

export type FleetContactForm = Pick<FleetFields, 'contact'>;

export default function EditFleetDetailsStep() {
	// Hooks
	const { t } = useTranslation('irp/supplements/edit_fleet');
	const navigate = useNavigate();
	const { supplementKey } = useTypedParams(EditFleetPaths.Details);
	const { supplement, reload: reloadSupplement } = useSupplement();
	const { openToast } = useToast();
	const fleetsService = useAPI(FleetsService);

	// State
	const [formik, setFormik] = useState<Parameters<FleetFormProps['onFormik']>[0] | null>(null);

	// Computed
	const fleet = supplement?.fleet;

	const handleNext = async () => {
		if (!formik) return undefined;

		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'irp/supplements/edit-fleet/details',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleSubmit = async (data: FleetFields) => {
		if (!fleet) return undefined;

		return fleetsService
			.update(fleet.key, data)
			.then(reloadSupplement)
			.then(() => {
				openToast({
					id: `irp/supplements/add-fleet/details`,
					message: t('toasts.updated', { ns: 'irp/fleets' }),
					severity: 'success',
				});
			})
			.then(() => navigate(EditFleetPaths.Verify.buildPath({ supplementKey })));
	};

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<FleetForm fleet={fleet || null} onFormik={setFormik} onSubmit={handleSubmit} />

			<SupplementStepFooter nextLabel={t('buttons.next', { ns: 'core' })} onNext={handleNext} />
		</Box>
	);
}
