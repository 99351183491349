import { DatePickerProps } from '@mui/x-date-pickers';
import { currencyFormat, dayjsFromObject, numberFormat } from 'core/services/intl';
import dayjs, { Dayjs } from 'dayjs';
import { Namespace, TFunction } from 'i18next';
import { VehicleInfoForm } from 'modules/irp/modules/supplements/modules/add_vehicle/components/AddVehicleInformation';
import {
	AddVehicleRegistrationProps,
	VehicleRegistrationForm,
} from 'modules/irp/modules/supplements/modules/add_vehicle/components/AddVehicleRegistration';
import { VehicleWeightGroupForm } from 'modules/irp/modules/supplements/modules/add_vehicle/components/AddVehicleWeightGroup';
import Vehicle, {
	VehicleBuyerType,
	VehicleFeeCalculationDate,
	VehicleFields,
	VehicleTransfer,
} from 'modules/irp/modules/vehicles/types/Vehicle';
import Date, { DateRange, DateValidationSchema, DateValidations } from 'types/Date';
import { LookupValueValidationSchema } from 'types/LookupValue';
import { SupplementFeeCalculationStrategy, SupplementType } from 'types/Supplement';
import { WeightGroupMaxGrossWeight, WeightGroupMinGrossWeight } from 'types/WeightGroup';
import * as Yup from 'yup';

export function VehicleInfoFormValidationSchema({
	t,
	registrantOnly,
	canRequestNewPlate,
}: {
	t: TFunction<Namespace>;
	registrantOnly?: boolean;
	canRequestNewPlate?: boolean;
}) {
	return {
		unitNumber: Yup.string()
			.required(t('data.validation.required', { ns: 'core' }))
			.max(10, t('data.validation.len.max', { ns: 'core', len: 10 })),
		vin: Yup.string()
			.transform((v) => v.toUpperCase())
			.matches(/^[A-Z0-9]+$/, t('data.validation.alphanumeric', { ns: 'core' }))
			.matches(/^.{5,17}$/, t('data.validation.vinLength', { ns: 'core' }))
			.required(t('data.validation.required', { ns: 'core' })),
		type: Yup.object()
			.shape(LookupValueValidationSchema)
			.required(t('data.validation.required', { ns: 'core' })),
		fuelType: Yup.object()
			.shape(LookupValueValidationSchema)
			.required(t('data.validation.required', { ns: 'core' })),
		model: Yup.object().shape<Partial<Record<keyof VehicleInfoForm['model'], Yup.AnySchema>>>({
			year: Yup.number()
				.test('required', t('data.validation.required', { ns: 'core' }), (v) => (v || 0) > 0)
				.min(1970, t('data.validation.gte', { ns: 'core', min: 1970 }))
				.max(
					new window.Date().getFullYear() + 1,
					t('data.validation.lte', { ns: 'core', max: new window.Date().getFullYear() + 1 }),
				),
			make: Yup.object()
				.shape(LookupValueValidationSchema)
				.required(t('data.validation.required', { ns: 'core' })),
		}),
		title: Yup.object().shape<Partial<Record<keyof VehicleInfoForm['title'], Yup.AnySchema>>>({
			number: Yup.string()
				.matches(/^[a-zA-Z0-9-]+$/, t('data.validation.alphanumeric', { ns: 'core' }))
				.required(t('data.validation.required', { ns: 'core' }))
				.max(50, t('data.validation.len.max', { ns: 'core', len: 50 })),
			state: Yup.object()
				.shape(LookupValueValidationSchema)
				.required(t('data.validation.required', { ns: 'core' })),
		}),
		registration: Yup.object().shape<Partial<Record<keyof VehicleInfoForm['registration'], Yup.AnySchema>>>({
			tinOfMcrs: Yup.string()
				.required(t('data.validation.required', { ns: 'core' }))
				.transform((v) => (v ? v.replace(/[^0-9]/g, '') : '')) // Remove non-numeric characters
				.length(9, t('data.validation.len.exactly', { ns: 'core', len: 9 })),
			usdotOfMcrs: (!registrantOnly
				? Yup.number().required(t('data.validation.required', { ns: 'core' }))
				: Yup.number().transform((val, orig) => (orig === '' ? undefined : val))
			).test('gt0', t('data.validation.gt', { ns: 'core', min: 0 }), (v) => (v === undefined ? true : v > 0)),
			mcrsExpectedToChange: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
			temporaryAuthorityNeeded: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
			iftaDecalNeeded: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
		}),
		purchase: Yup.object().shape<Partial<Record<keyof VehicleInfoForm['purchase'], Yup.AnySchema>>>({
			price: Yup.number()
				.test('required', t('data.validation.required', { ns: 'core' }), (v) => (v || 0) > 0)
				.min(1_000, t('data.validation.gte', { ns: 'core', min: currencyFormat(1_000) }))
				.max(750_000, t('data.validation.lte', { ns: 'core', max: currencyFormat(750_000) })),
		}),
		plate: Yup.object().shape<Partial<Record<keyof VehicleInfoForm['plate'], Yup.AnySchema>>>({
			personalized: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
			requestNew: canRequestNewPlate
				? Yup.boolean().required(t('data.validation.required', { ns: 'core' }))
				: Yup.boolean().strip(),
			number: Yup.string().when('personalized', {
				is: true,
				then: (ss) => ss.required(t('data.validation.required', { ns: 'core' })).transform((v) => v.toUpperCase()),
			}),
		}),
	};
}

export function VehicleWeightGroupFormValidationSchema({
	t,
	model,
	fields,
}: {
	t: TFunction<Namespace>;
	model?: Yup.AnyObjectSchema;
	fields: VehicleFields | null;
}) {
	const maxGrossWeightBetween = t('data.validation.between', {
		ns: 'core',
		min: numberFormat(WeightGroupMinGrossWeight),
		max: `${numberFormat(WeightGroupMaxGrossWeight)} ${t('data.units.pounds', { ns: 'core' })}`,
	});

	return Yup.object<VehicleWeightGroupForm>()
		.shape({
			pullsTrailer: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
			trailerExceedsMilesThreshold: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
			unladenWeight: Yup.number()
				.test('required', t('data.validation.required', { ns: 'core' }), (v) => (v || 0) > 0)
				.min(WeightGroupMinGrossWeight, maxGrossWeightBetween)
				.max(WeightGroupMaxGrossWeight, maxGrossWeightBetween),
			weightGroup: Yup.object()
				.shape(LookupValueValidationSchema)
				.required(t('data.validation.required', { ns: 'core' }))
				.test('required', t('data.validation.required', { ns: 'core' }), (v) => !!v.id && v.id > 0),
			axles: Yup.object().when('pullsTrailer', ([pullsTrailer], schema) => {
				if (pullsTrailer) {
					return schema.shape<Partial<Record<keyof VehicleWeightGroupForm['axles'], Yup.AnySchema>>>({
						unit: Yup.number()
							.test('required', t('data.validation.required', { ns: 'core' }), (v) => (v || 0) > 0)
							.min(
								2,
								t('data.validation.min', {
									ns: 'core',
									min: 2,
									field: t('vehicle.axles.title', { ns: 'data' }).toLowerCase(),
								}),
							),
						combined: Yup.number()
							.test('required', t('data.validation.required', { ns: 'core' }), (v) => (v || 0) > 0)
							.min(
								3,
								t('data.validation.min', {
									ns: 'core',
									min: 3,
									field: t('vehicle.axles.title', { ns: 'data' }).toLowerCase(),
								}),
							)
							.max(
								20,
								t('data.validation.max', {
									ns: 'core',
									max: 20,
									field: t('vehicle.axles.title', { ns: 'data' }).toLowerCase(),
								}),
							),
					});
				}
				return schema.shape<Partial<Record<keyof VehicleWeightGroupForm['axles'], Yup.AnySchema>>>({
					unit: Yup.number()
						.test('required', t('data.validation.required', { ns: 'core' }), (v) => (v || 0) > 0)
						.min(
							2,
							t('data.validation.min', {
								ns: 'core',
								min: 2,
								field: t('vehicle.axles.title', { ns: 'data' }).toLowerCase(),
							}),
						),
					combined: Yup.number().strip(),
				});
			}),
			model: (model || Yup.object()).shape({
				seats:
					// Required if Vehicle is a Bus
					fields?.type?.code === 'BS'
						? Yup.number().test('required', t('data.validation.required', { ns: 'core' }), (v) => (v || 0) > 0)
						: Yup.number().strip(),
			}),
		})
		.required(t('data.validation.required', { ns: 'core' }));
}

export function VehicleRegistrationFormValidationSchema(
	strategy: SupplementFeeCalculationStrategy,
	{
		t,
		modelYear,
		registrationYear,
		registration,
		plate,
	}: {
		t: TFunction<Namespace>;
		modelYear: number;
		registrationYear?: DateRange;

		// Schema extensions
		registration?: Yup.AnyObjectSchema;
		plate?: Yup.AnyObjectSchema;
	},
	excludeSellerFields: boolean,
	feeCalculationValidations?: AddVehicleRegistrationProps['feeCalculationValidations'],
) {
	const required = t('data.validation.required', { ns: 'core' });

	// Standard validations we apply to ALL fee calculation date fields
	const feeCalcDateValidations = (s: Yup.ObjectSchema<Date>, isFeeCalcDate: boolean): Yup.ObjectSchema<Date> => {
		let validation = s;

		// Fee calculation date must be after the vehicle model year
		validation = validation.test(
			'afterModelYear',
			t('vehicle.registration.toasts.feeCalcModelYear', { ns: 'irp/supplements/add_vehicle', year: modelYear - 1 }),
			(v) => !v || (v?.year || 0) >= modelYear - 1,
		);

		// Must not exceed 30 days in the future, only if registration has started
		if (registrationYear && dayjsFromObject(registrationYear.startDate)?.isBefore(dayjs())) {
			validation = validation.test(
				'max30daysInFuture',
				t('vehicle.registration.validation.feeCalcMax30Days', { ns: 'irp/supplements/add_vehicle' }),
				(v) => {
					if (!v) return true;
					const date = dayjsFromObject(v as Date);
					return !date || date.isBefore(dayjs().add(30, 'day'));
				},
			);
		}

		// Fee calculation date must be within the registration year
		if (strategy === SupplementFeeCalculationStrategy.VehicleFeeCalculationDate && isFeeCalcDate) {
			validation = validation.test(
				'withinRegistrationYear',
				t('vehicle.registration.validation.feeCalcWithinFleetReg', { ns: 'irp/supplements/add_vehicle' }),
				(v) =>
					!dayjsFromObject(v as Date)?.isBefore(dayjsFromObject(registrationYear?.startDate)) &&
					!dayjsFromObject(v as Date)?.isAfter(dayjsFromObject(registrationYear?.endDate)),
			);
		}

		// Apply custom validations
		if (
			strategy === SupplementFeeCalculationStrategy.VehicleFeeCalculationDate &&
			isFeeCalcDate &&
			feeCalculationValidations
		) {
			feeCalculationValidations.forEach((v) => {
				validation = validation.test(v.name, v.message, v.test);
			});
		}

		return validation;
	};

	const maxGrossWeightBetween = t('data.validation.between', {
		ns: 'core',
		min: numberFormat(WeightGroupMinGrossWeight),
		max: `${numberFormat(WeightGroupMaxGrossWeight)} ${t('data.units.pounds', { ns: 'core' })}`,
	});

	const feeCalculationDateValidations = (feeCalcDate: VehicleFeeCalculationDate) =>
		// All fee calculation dates are optional unless they are the selected fee calculation date
		DateValidationSchema.when('feeCalculationDate.code', {
			is: feeCalcDate,
			then: (s) => feeCalcDateValidations(s, true).required(required),
			otherwise: (s) => feeCalcDateValidations(s, false).nullable(),
		});

	return Yup.object<VehicleRegistrationForm>()
		.shape({
			registration: (registration || Yup.object())
				.shape<Partial<Record<keyof VehicleRegistrationForm['registration'], Yup.AnySchema>>>({
					owner: Yup.string().required(required).min(1),
					feeCalculationDate: Yup.object().shape(LookupValueValidationSchema).required(required),
					leaseDate: feeCalculationDateValidations(VehicleFeeCalculationDate.Lease),
					otherDate: feeCalculationDateValidations(VehicleFeeCalculationDate.Other),
					firstOperatedDate: feeCalculationDateValidations(VehicleFeeCalculationDate.FirstOperated),
					mcoProvided: Yup.boolean(),
					specialTruckStateFlags: Yup.object().shape({
						CO: Yup.boolean().required(required),
						UT: Yup.boolean().required(required),
					}),
					backFeeAssessment: Yup.object().shape<
						Record<keyof Vehicle['registration']['backFeeAssessment'], Yup.AnySchema>
					>({
						enabled: Yup.boolean(),
						unpaidMonths: Yup.number().when('enabled', {
							is: true,
							then: (ss) =>
								ss
									.min(
										1,
										t('data.validation.between', {
											ns: 'core',
											min: 1,
											max: `36 ${t('data.units.months', { ns: 'core' })}`,
										}),
									)
									.max(
										36,
										t('data.validation.between', {
											ns: 'core',
											min: 1,
											max: `36 ${t('data.units.months', { ns: 'core' })}`,
										}),
									)
									.test('required', required, (v) => (v || 0) > 0),
							otherwise: (ss) => ss.strip(),
						}),
						registeredWeight: Yup.number().when('enabled', {
							is: true,
							then: (ss) =>
								ss
									.min(1, maxGrossWeightBetween)
									.max(WeightGroupMaxGrossWeight, maxGrossWeightBetween)
									.test('required', required, (v) => (v || 0) > 0),
							otherwise: (ss) => ss.strip(),
						}),
					}),
				})
				.required(required),
			purchase: Yup.object()
				.when('registration.feeCalculationDate.code', ([feeCalcCode], schema) => {
					return schema.shape<Partial<Record<keyof VehicleRegistrationForm['purchase'], Yup.AnySchema>>>({
						date: feeCalcDateValidations(DateValidationSchema, feeCalcCode === VehicleFeeCalculationDate.Purchase)
							// Purchase date is always required
							.required(required)
							// Purchase date cannot be in the future
							.test('noFutureDate', DateValidations.noFutureDate(t)),
						price: Yup.number()
							.test('required', required, (v) => (v || 0) > 0)
							.min(1_000, t('data.validation.gte', { ns: 'core', min: currencyFormat(1_000) }))
							.max(750_000, t('data.validation.lte', { ns: 'core', max: currencyFormat(750_000) })),
						buyerType: Yup.object()
							.nullable()
							.shape(LookupValueValidationSchema)
							.test('required', required, (v) => !!v?.id && v.id > 0),

						// dealer info not required on edit or renewal/edit
						dealerName: Yup.string().when('buyerType.code', {
							is: VehicleBuyerType.Dealer,
							then: (s) => (!excludeSellerFields ? s.required(required) : s.optional()),
							otherwise: (s) => s.strip(),
						}),

						// dealer info not required on edit or renewal/edit
						dealerState: Yup.object()
							.shape(LookupValueValidationSchema)
							.when('buyerType.code', {
								is: VehicleBuyerType.Dealer,
								then: (s) => (!excludeSellerFields ? s.required(required) : s.notRequired()),
								otherwise: (s) => s.strip(),
							}),
					});
				})
				.required(required),
			// plate info not required on edit or renewal/edit
			plate: (plate || Yup.object()).when('purchase', {
				is: (v: VehicleRegistrationForm['purchase']) => v?.buyerType?.code === VehicleBuyerType.Private,
				then: (s) => {
					const sellerRequired = (ss: Yup.AnySchema) =>
						!excludeSellerFields ? ss.required(required) : ss.notRequired();
					return s
						.shape<Record<keyof VehicleRegistrationForm['plate'], Yup.AnySchema>>({
							previous: Yup.object().shape({
								number: sellerRequired(
									Yup.string()
										.transform((v) => v.toUpperCase())
										.matches(/^[A-Z0-9]+$/, t('data.validation.alphanumeric', { ns: 'core' })),
								),
								state: sellerRequired(Yup.object().shape(LookupValueValidationSchema)),
								expirationDate: sellerRequired(DateValidationSchema),
							}),
						})
						.notRequired();
				},
			}),
			countyCredits: Yup.object().shape<Record<keyof VehicleRegistrationForm['countyCredits'], Yup.AnySchema>>({
				enabled: Yup.boolean(),
				searchPlate: Yup.string().when('enabled', {
					is: true,
					then: (ss) =>
						ss
							.required(required)
							.transform((v) => v.toUpperCase())
							.matches(/^[A-Z0-9]+$/, t('data.validation.alphanumeric', { ns: 'core' })),
					otherwise: (ss) => ss.strip(),
				}),
			}),
			factoryPrice: Yup.number()
				.test('required', required, (v) => (v || 0) > 0)
				.min(1_000, t('data.validation.gte', { ns: 'core', min: currencyFormat(1_000) }))
				.max(750_000, t('data.validation.lte', { ns: 'core', max: currencyFormat(750_000) })),
		})
		.required(required);
}

export const getFeeCalcDateObject = (code: VehicleFeeCalculationDate, v: Vehicle) => {
	switch (code) {
		case VehicleFeeCalculationDate.Lease:
			return v.registration.leaseDate;
		case VehicleFeeCalculationDate.Other:
			return v.registration.otherDate;
		case VehicleFeeCalculationDate.FirstOperated:
			return v.registration.firstOperatedDate;
		case VehicleFeeCalculationDate.Purchase:
			return v.purchase?.date;
		default:
			return undefined;
	}
};

export const getMinMaxDatesForAdd = (registrationYear: DateRange, row: Vehicle): (Dayjs | undefined)[] => {
	// default values - within the registration period
	let minDate = dayjsFromObject(registrationYear.startDate);
	let maxDate = dayjsFromObject(registrationYear.endDate);

	// If reg period hasn't started, allow only the first month
	if (minDate?.isAfter(dayjs())) {
		minDate = dayjsFromObject(registrationYear.startDate)?.startOf('month');
		maxDate = dayjsFromObject(registrationYear.endDate)?.endOf('month');
		return [minDate, maxDate];
	}

	const code = row?.registration?.feeCalculationDate?.code || null;
	if (!code) return [minDate, maxDate];
	const feeCalcDate = dayjsFromObject(getFeeCalcDateObject(code, row));
	if (feeCalcDate && feeCalcDate.year() < row.model.year - 1) {
		minDate = dayjs()
			.year(row.model.year - 1)
			.startOf('year');
	}

	switch (code) {
		case VehicleFeeCalculationDate.Purchase:
			return [minDate, dayjs()];
		case VehicleFeeCalculationDate.FirstOperated: // intentional fallthrough
		case VehicleFeeCalculationDate.Lease:
		case VehicleFeeCalculationDate.Other:
			return [minDate, dayjs().add(30, 'day')];
		default:
			return [minDate, maxDate];
	}
};

export interface FeeCalculationDateValidationProps {
	t: TFunction<Namespace>;
	registrationYear: DateRange;
	strategy?: SupplementFeeCalculationStrategy;
	supplementType?: SupplementType;
}

export const feeCalculationDateValidation =
	({ t, registrationYear, strategy, supplementType }: FeeCalculationDateValidationProps) =>
	(v: Yup.AnyObject, { createError, parent, path }: Yup.TestContext) => {
		const feeCalcDate = dayjsFromObject(getFeeCalcDateObject(v.feeCalculationDate.code, parent));

		if (!feeCalcDate) return true;

		// Apply custom validations based on supplementType before date-based validations
		if (supplementType) {
			switch (supplementType) {
				// add cases here, e.g. for edit-vehicle
				default:
					break;
			}
		}

		// Apply custom validations based on strategy before date-based validations
		if (strategy) {
			switch (strategy) {
				// add cases here, e.g. for edit-vehicle
				default:
					break;
			}
		}

		// fee calc date must not exceed 30 days in the future
		if (dayjsFromObject(registrationYear.startDate)?.isBefore(dayjs())) {
			if (!feeCalcDate.isBefore(dayjs().add(30, 'day'))) {
				return createError({
					path,
					message: t('vehicles.errors.feeCalculationDate.max30DaysInFuture', { ns: 'irp/supplements' }),
				});
			}
		}

		// if fee calc date is purchase date, cannot be in future
		if (v.feeCalculationDate.code === VehicleFeeCalculationDate.Purchase) {
			if (!feeCalcDate.isBefore(dayjs())) {
				return createError({
					path,
					message: t('vehicles.errors.feeCalculationDate.noFuturePurchaseDate', { ns: 'irp/supplements' }),
				});
			}
		}

		// fee calc date must be within registration year
		if (
			feeCalcDate.isBefore(dayjsFromObject(registrationYear.startDate)) ||
			feeCalcDate.isAfter(dayjsFromObject(registrationYear.endDate))
		) {
			return createError({
				path,
				message: t('vehicles.errors.feeCalculationDate.outsideRegYear', { ns: 'irp/supplements' }),
			});
		}

		// Fee calculation date must be after the vehicle model year
		const modelYear = parent.model.year;
		if (modelYear) {
			if (feeCalcDate.year() < modelYear - 1) {
				return createError({ path, message: t('vehicles.errors.modelYear', { ns: 'irp/supplements' }) });
			}
		}

		return true;
	};

export interface TransferFeeCalcTestProps {
	t: TFunction<Namespace>;
}

export const transferFeeCalcTest =
	({ t }: TransferFeeCalcTestProps) =>
	(v: Yup.AnyObject, { createError, parent, path }: Yup.TestContext) => {
		// transferVehicle will not exist on first page load
		if (!parent.transferVehicle) return true;

		const { registration } = parent.transferVehicle;
		const { feeCalculationDate } = registration;
		const fcdDateObj = dayjsFromObject(getFeeCalcDateObject(feeCalculationDate.code, parent.transferVehicle));
		const deactivateDate = dayjsFromObject(v.date);
		if (!fcdDateObj || !deactivateDate) {
			return false;
		}

		// fee calc date must follow deactivate date
		if (!fcdDateObj.isSameOrAfter(deactivateDate, 'day')) {
			return createError({
				path,
				message: t('vehicles.errors.feeCalculationDate.beforeDeactivateDate', { ns: 'irp/supplements' }),
			});
		}

		// fee calc date must be same month as deactivate date of deleted vehicle
		if (!fcdDateObj.isSame(deactivateDate, 'month')) {
			return createError({
				path,
				message: t('vehicles.errors.feeCalculationDate.sameMonth', { ns: 'irp/supplements' }),
			});
		}
		return true;
	};

type ErrorValue = string | { [key: string]: ErrorValue };
type ErrorObj = Record<string, ErrorValue>;

const flattenRecursively = (obj: ErrorObj, output: Map<string, string>): Map<string, string> => {
	Object.entries(obj).forEach(([k, v]) => {
		if (typeof v === 'string') {
			output.set(k, v);
			return null;
		}
		return flattenRecursively(v, output);
	});

	return output;
};

export const getFeeCalcTooltipError = (errors: Record<string, ErrorValue> | undefined) => {
	if (!errors) return '';
	const output = new Map<string, string>();
	flattenRecursively(errors, output);

	return (
		output.get('deactivate') ||
		output.get('date') ||
		output.get('leaseDate') ||
		output.get('purchaseDate') ||
		output.get('otherDate') ||
		output.get('firstOperatedDate')
	);
};

export const getMinMaxDatesForTransfer = (v: VehicleTransfer): DatePickerProps<dayjs.Dayjs> => {
	// defaults: must be in same month but not precede delete date
	const minDate = dayjsFromObject(v.deactivate?.date);
	const maxDate = dayjsFromObject(v.deactivate?.date)?.endOf('month');

	if (v.transferVehicle?.registration?.feeCalculationDate?.code === VehicleFeeCalculationDate.Purchase) {
		return { minDate, maxDate, disableFuture: true };
	}

	return { minDate, maxDate, disableFuture: false };
};
