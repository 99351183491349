import ApiService from 'core/services/api';
import { IrpJurisdiction } from 'types/IrpJurisdiction';
import IrpRegistration, { IrpRegistrationFields } from 'types/IrpRegistration';

// TODO move to IRP module when it's created

export default class IrpService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/irp`);
	}

	async listJurisdictions(): Promise<IrpJurisdiction[]> {
		const { jurisdictions } = await this.GET<{ jurisdictions: IrpJurisdiction[] }>(`/jurisdictions`, undefined);
		return jurisdictions;
	}

	async updateRegistration(id: number, registration: IrpRegistrationFields): Promise<IrpRegistration> {
		return this.PUT<{ registration: IrpRegistrationFields }, IrpRegistration>(`/${id}`, { registration });
	}
}
