import { CanAccess } from 'core/components';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import SupplementPageContainer from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import WeightGroupChangeSteps from 'modules/irp/modules/supplements/modules/weight_group_change/components/WeightGroupChangeSteps';
import SupplementBlocker from 'modules/irp/modules/supplements/providers/SupplementProvider';
import WeightGroupPaths from 'modules/irp/modules/weight_groups/routes/paths';
import { lazy, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Permissions from 'types/Permissions';
import WeightGroupChangePaths from './paths';
import WeightGroupChangeDetailsStep from './WeightGroupChangeDetailsStep';

const WeightGroupChangeDocumentsStep = lazy(() => import('./WeightGroupChangeDocumentsStep'));
const WeightGroupChangeVerifyStep = lazy(() => import('./WeightGroupChangeVerifyStep'));
const WeightGroupChangeSubmitStep = lazy(() => import('./WeightGroupChangeSubmitStep'));

function WeightGroupChangeIndex() {
	const { supplementKey } = useTypedParams(WeightGroupChangePaths);
	return <Redirect to={WeightGroupChangePaths.Details.buildPath({ supplementKey })} replace />;
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/weight_group_change');
	return (
		<SupplementBlocker
			basePath={WeightGroupChangePaths}
			title={t('dialogs.close.title', { ns: 'irp/supplements/weight_group_change' })}
		>
			{children}
		</SupplementBlocker>
	);
}

// Route component mappings
const WeightGroupChangeRoutes = [
	{
		path: WeightGroupChangePaths.path,
		element: (
			<CanAccess resource={Permissions.IRP.WeightGroups.resource} action={Actions.UPDATE} showError>
				<SupplementPageContainer
					breadcrumbs={['irp/supplements/weight_group_change', 'irp/supplements', 'irp/vehicles']}
					stepRoutes={[
						WeightGroupChangePaths.Details.path,
						WeightGroupChangePaths.Documentation.path,
						WeightGroupChangePaths.Verify.path,
						WeightGroupChangePaths.Submit.path,
					]}
					closePath={WeightGroupPaths.buildPath({})}
					steps={<WeightGroupChangeSteps />}
				>
					<Outlet />
				</SupplementPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'irp/supplements/weight_group_change' }),
		},
		children: [
			// Index route
			{
				path: WeightGroupChangePaths.path,
				index: true,
				element: <WeightGroupChangeIndex />,
			},

			// Steps
			{
				path: WeightGroupChangePaths.Details.path,
				element: (
					<WithBlocker>
						<WeightGroupChangeDetailsStep />
					</WithBlocker>
				),
			} as Route<typeof WeightGroupChangePaths.Details>,
			{
				path: WeightGroupChangePaths.Documentation.path,
				element: (
					<WithBlocker>
						<WeightGroupChangeDocumentsStep />
					</WithBlocker>
				),
			} as Route<typeof WeightGroupChangePaths.Documentation>,
			{
				path: WeightGroupChangePaths.Verify.path,
				element: (
					<WithBlocker>
						<WeightGroupChangeVerifyStep />
					</WithBlocker>
				),
			} as Route<typeof WeightGroupChangePaths.Verify>,
			{
				path: WeightGroupChangePaths.Submit.path,
				element: (
					<WithBlocker>
						<WeightGroupChangeSubmitStep />
					</WithBlocker>
				),
			} as Route<typeof WeightGroupChangePaths.Submit>,
		],
	} as Route<typeof WeightGroupChangePaths>,
];

export default WeightGroupChangeRoutes;
