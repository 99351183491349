import { Module } from 'core/types/module';
import { Actions } from 'core/types/permissions';
import HistoryRoutes from './routes';

const HistoryModule: Module = {
	// ID of the module
	id: 'history',

	// Navigation bar items
	nav: [],

	// Permission
	permission: { resource: 'history', action: Actions.READ },

	// Routes
	routes: HistoryRoutes,
};

export default HistoryModule;
