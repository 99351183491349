import { Loader } from 'core/components';
import ClearFleetError from 'core/components/ClearFleetError';
import { useAPI } from 'core/hooks';
import TransmittalsService from 'modules/transmittals/api/TransmittalsService';
import Transmittal from 'modules/transmittals/types/Transmittal';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

type TransmittalContextProps = {
	transmittal: Transmittal;
	reload: () => Promise<Transmittal | void>;
};

// Create context
export const TransmittalContext = createContext<TransmittalContextProps | null | undefined>(undefined);

export interface TransmittalProviderProps {
	id: number;
}

export function TransmittalProvider({ id, children }: PropsWithChildren<TransmittalProviderProps>) {
	const transmittalsService = useAPI(TransmittalsService);

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [transmittal, setTransmittal] = useState<Transmittal | null>(null);
	const [error, setError] = useState<Error | null>(null);

	// Reload transmittal
	const reload = useCallback(async () => {
		setLoading(true);
		setError(null);

		return transmittalsService
			.get(id)
			.then(setTransmittal)
			.catch(setError)
			.finally(() => setLoading(false));
	}, [transmittalsService, id]);

	// Load transmittal on mount
	useEffect(() => {
		reload();
	}, [reload]);

	// Memoize context value
	const value = useMemo<TransmittalContextProps | null>(() => {
		if (!transmittal) return null;
		return { transmittal, reload };
	}, [transmittal, reload]);

	// If there is an error, display it
	if (error) return <ClearFleetError error={error} />;

	// Loading transmittal
	if (loading && !transmittal) return <Loader sx={{ flex: 1 }} />;

	// Provide context with transmittal
	return <TransmittalContext.Provider value={value}>{children}</TransmittalContext.Provider>;
}

export function useTransmittal(): TransmittalContextProps {
	const context = useContext(TransmittalContext as React.Context<TransmittalContextProps>);
	if (context === undefined) {
		throw new Error('useTransmittal must be used within a TransmittalProvider');
	}

	return context;
}
