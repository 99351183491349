import ClearFleetError from 'core/components/ClearFleetError';
import { useAPI } from 'core/hooks';
import CarriersService from 'modules/accounts/api/CarriersService';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import SupplementCreator from 'modules/irp/modules/supplements/components/SupplementCreator';
import newAccountRequestFromCarrier from 'modules/irp/modules/supplements/modules/new_account/helpers/newAccountRequestFromCarrier';
import NewAccountPaths from 'modules/irp/modules/supplements/modules/new_account/routes/paths';
import SupplementPaths from 'modules/irp/modules/supplements/routes/paths';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

export default function NewAccountSupplementCreator() {
	// Hooks
	const { t } = useTranslation('irp/supplements/new_account');
	const [{ accountKey }] = useTypedSearchParams(NewAccountPaths);
	const navigate = useNavigate();

	// Services
	const carriersService = useAPI(CarriersService);
	const supplementsService = useAPI(SupplementsService);

	// State
	const [error, setError] = useState<Error | null>(null);

	// New supplement, create it
	const create = useCallback(() => {
		// No account key, redirect to the supplements page
		if (!accountKey) return navigate(SupplementPaths.buildPath({}));

		// Create supplement
		return carriersService
			.get(accountKey, { address: true })
			.then(newAccountRequestFromCarrier)
			.then((carrier) => supplementsService.create(carrier))
			.then((supplement) => {
				// Replace the URL to prevent a second account creation attempt
				navigate(NewAccountPaths.buildPath({ supplementKey: supplement.key }), { replace: true });
			})
			.catch(setError);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountKey]);

	// Error'd
	if (error) return <ClearFleetError error={{ title: t('create.error'), ...error }} />;

	// New supplement, create
	return <SupplementCreator message={t('create.loading')} create={create} />;
}
