import { CanAccess } from 'core/components';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import FleetsRoutes from 'modules/irp/modules/fleets/routes';
import SupplementsRoutes from 'modules/irp/modules/supplements/routes';
import WeightGroupRoutes from 'modules/irp/modules/weight_groups/routes';
import RazorPaths from 'modules/razor/paths';
import { Outlet } from 'react-router-dom';
import Permissions from 'types/Permissions';
import IrpPaths from './paths';

// Route component mappings
const IrpRoutes = [
	{
		path: IrpPaths.path,
		element: (
			<CanAccess resource={Permissions.IRP.resource} action={Actions.READ} showError>
				<Outlet />
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'irp' }),
		},
		children: [
			// Index route
			{
				path: IrpPaths.path,
				index: true,
				element: <Redirect to={RazorPaths.Manage.WorkInProcess.buildPath({})} replace />,
			},

			// Sub-module routes
			...FleetsRoutes,
			...SupplementsRoutes,
			...WeightGroupRoutes,
		],
	} as Route<typeof IrpPaths>,
];

export default IrpRoutes;
