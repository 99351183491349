import { number, route, string } from 'react-router-typesafe-routes/dom';

const WeightGroupPaths = route(
	'irp/weight-groups',
	{
		searchParams: {
			registrationYear: number(),
			accountKey: string(),
		},
	},
	{
		Add: route('add', {
			searchParams: {
				registrationYear: number(),
			},
		}),
		View: route(':key'),
		Edit: route(':key/edit'),
	},
);

export default WeightGroupPaths;
