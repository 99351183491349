import Date from 'types/Date';
import Program from 'types/Program';
import Tracking from 'types/Tracking';

interface Transmittal {
	id: number;
	program: Program;
	period: {
		start: Date;
		end: Date;
	};
	sequenceId: number;
	step: TransmittalStep;
	tracking: Tracking;

	// Program specific details
	ifta?: {
		transactions: number;
		amount: number;
	};
	irp?: {
		transactions: number;
		registrations: number;
	};
}

export default Transmittal;

export enum TransmittalStep {
	Started = 'STEP_STARTED',
	Confirmed = 'STEP_CONFIRMED',
	Testing = 'STEP_TESTING',
	Production = 'STEP_PRODUCTION',
	Remitted = 'STEP_REMITTED',
}
