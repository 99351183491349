import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import { UUIDFormat } from 'types/UUID';
import { string } from 'yup';

const RazorPaths = route(
	'r',
	{
		searchParams: {
			accountKeyId: yup(string().matches(UUIDFormat).required()).defined(),
		},
	},
	{
		Correspondence: route(
			'correspondence',
			{},
			{
				History: route('history'),
				Print: route('print'),
			},
		),
		ErrorCorrections: route('ErrorCorrection/Start'),
		Inventory: route(
			'inventory',
			{},
			{
				PlateReturn: route('platereturn'),
			},
		),
		Login: route(
			'login',
			{},
			{
				CreateAccount: route('createaccount', {
					searchParams: { new: yup(string()) },
				}),
			},
		),
		Manage: route(
			'manage',
			{},
			{
				Account: route('account'),
				AccountSettings: route('accountsettings'),
				Bonds: route('bonds'),
				Credentials: route('credentials'),
				Decals: route('decals'),
				Documents: route('documents'),
				Escrow: route('escrow'),
				Fleets: route('fleets'),
				IftaLicense: route('iftalicense'),
				IftaQuarterlyReturn: route('iftaquarterlyreturn/start'),
				QuarterlyReturns: route('quarterlyreturns'),
				ServiceProviders: route('serviceproviders'),
				Supplements: route('supplements', {
					searchParams: {
						supplementKeyId: yup(string()),
					},
				}),
				Vehicles: route('vehicles'),
				WeightGroups: route('weightgroups'),
				WorkInProcess: route('workinprocess'),
			},
		),
		Payments: route('payments/payments'),
		RefundQueue: route('refundqueue'),
		Registration: route(
			'Registration',
			{},
			{
				Programs: route('ReviewRequirements'),
			},
		),
		Reports: route('reports'),
	},
);

export default RazorPaths;
