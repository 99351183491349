import ApiService, { ApiError } from 'core/services/api';
import Vehicle, { VehicleIncludeFields, VehicleSearchFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import Fleet, { FleetActions, FleetFields, FleetIncludes, Mileage, MileageType } from 'types/Fleet';
import LookupValue from 'types/LookupValue';
import WeightGroup, { WeightGroupFields } from 'types/WeightGroup';

// TODO move to Fleets module at some point
export default class FleetsService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/fleets`);
	}

	async listTypes(): Promise<LookupValue[]> {
		const path = '/config/types';
		const { types } = await this.GET<{ types: LookupValue[] }>(path, undefined, {});
		return types;
	}

	async listCommodityClasses(type: number): Promise<LookupValue[]> {
		const path = `/config/commodityClasses?type=${type}`;
		const { commodityClasses } = await this.GET<{ commodityClasses: LookupValue[] }>(path, undefined, {});
		return commodityClasses;
	}

	// Search for vehicles attached to a fleet
	async searchVehicles(
		fleetKey: string,
		fields: FleetsSearchVehiclesRequest,
		includes?: VehicleIncludeFields,
	): Promise<Vehicle[]> {
		const { vehicles } = await this.GET<{ vehicles: Vehicle[] }>(`/${fleetKey}/vehicles`, undefined, {
			params: {
				...fields,
				include: includes && Object.keys(includes).length > 0 ? Object.keys(includes).join(',') : undefined,
			},
		});

		return vehicles || [];
	}

	async createWeightGroup(fleetKey: string, fields: WeightGroupFields): Promise<WeightGroup> {
		const { weightGroup } = await this.POST<{ weightGroup: WeightGroupFields }, { weightGroup: WeightGroup }>(
			`${fleetKey}/weightGroups`,
			{ weightGroup: fields },
		);
		return weightGroup;
	}

	async get(fleetKey: string, includes?: FleetIncludes): Promise<Fleet> {
		const { fleet } = await this.GET<{ fleet: Fleet }>(fleetKey, undefined, {
			params: {
				include: includes && Object.keys(includes).length > 0 ? Object.keys(includes).join(',') : undefined,
			},
		});
		return fleet;
	}

	async getMileage(fleetKey: string, type: MileageType): Promise<Mileage[]> {
		const path = `/${fleetKey}/mileage`;
		const { mileage } = await this.GET<{ mileage: Mileage[] }>(path, undefined, {
			params: {
				type,
			},
		});
		return mileage;
	}

	async update(fleetKey: string, fields: FleetFields & FleetActions): Promise<Fleet> {
		const { mileageType, ...fleet } = fields;
		const resp = await this.PUT<{ fleet: FleetFields } & FleetActions, { fleet: Fleet }>(fleetKey, {
			fleet,
			mileageType,
		});
		return resp.fleet;
	}

	// Validate one or more fleet fields individually
	// This method is used for early erroring of form fields
	async validate(fleetKey: string | null, fleet: FleetFields): Promise<ApiError[]> {
		const path = fleetKey ? `/${fleetKey}/validate` : '/validate';

		const { errors } = await this.POST<{ fleet: FleetFields }, { errors: null | ApiError[] }>(path, {
			fleet,
		});
		return errors || [];
	}

	async deactivate(fleet: Fleet) {
		return this.DELETE(`/${fleet.key}/active`);
	}

	async reactivate(fleet: Fleet) {
		return this.PUT(`/${fleet.key}/active`);
	}
}

export type FleetsSearchVehiclesRequest = Pick<VehicleSearchFields, 'vin' | 'titleNumber' | 'unitNumber'> & {
	weightGroupType?: number;
	active?: true;
};
