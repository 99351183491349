import { Grid } from '@mui/material';
import { Breadcrumbs, PageContainer } from 'core/components';
import TransmittalSteps from 'modules/transmittals/components/TransmittalSteps';
import TransmittalStepStart from 'modules/transmittals/routes/TransmittalStepStart';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Program from 'types/Program';
import TransmittalPaths from './paths';

export default function TransmittalsStart() {
	// Hooks
	const { program } = useTypedParams(TransmittalPaths.Program);

	return (
		<PageContainer>
			<Breadcrumbs ns="transmittals" />

			<Grid container spacing={3} wrap="wrap">
				<Grid item xs={12} xl={9} display="flex" flexDirection="column" rowGap={3}>
					<TransmittalSteps program={`PROGRAM_${program}` as Program} />

					<TransmittalStepStart />
				</Grid>
			</Grid>
		</PageContainer>
	);
}
