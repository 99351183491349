import Vehicle, { vehicleHasChanged } from 'modules/irp/modules/vehicles/types/Vehicle';

export enum VehicleStatus {
	Add = 'add',
	Edit = 'edit',
	Renew = 'renew',
	Delete = 'delete',
}

export function vehicleStatus(vehicle: Vehicle): VehicleStatus {
	switch (true) {
		case !!vehicle.deactivate:
			return VehicleStatus.Delete;
		case vehicle.clonedFrom && !vehicleHasChanged(vehicle, vehicle.clonedFrom):
			return VehicleStatus.Renew;
		case vehicle.clonedFrom && vehicleHasChanged(vehicle, vehicle.clonedFrom):
			return VehicleStatus.Edit;
		default:
			return VehicleStatus.Add;
	}
}
