import ClearFleetError from 'core/components/ClearFleetError';
import { useAPI } from 'core/hooks';
import FleetsService from 'modules/irp/modules/fleets/api/FleetsService';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Fleet, { FleetIncludes, FleetNew } from 'types/Fleet';

type FleetContextProps = {
	loading: boolean;
	fleet: Fleet | null;
	reload: () => void;
};

const DefaultFleetContext: FleetContextProps = {
	loading: true,
	fleet: null,
	reload: () => null,
};

const FleetContext = createContext<FleetContextProps>(DefaultFleetContext);

export interface FleetProviderProps {
	fleetKey: string;
	includes?: FleetIncludes;
}

export function FleetProvider({ fleetKey, includes, children }: PropsWithChildren<FleetProviderProps>) {
	const fleetsService = useAPI(FleetsService);

	// State
	const [loading, setLoading] = useState<boolean>(fleetKey !== FleetNew);
	const [fleet, setFleet] = useState<Fleet | null>(null);
	const [error, setError] = useState<Error | null>(null);

	const reload = useCallback(() => {
		// Load once
		if (!fleetKey) return;

		// Nothing to load for new fleets
		if (fleetKey === FleetNew) return;

		setLoading(true);
		fleetsService
			.get(fleetKey, includes)
			.then(setFleet)
			.catch(setError)
			.finally(() => setLoading(false));
	}, [fleetKey, fleetsService, includes]);

	useEffect(() => {
		reload();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fleetKey]);

	const value = useMemo<FleetContextProps>(() => {
		return { loading, reload, fleet };
	}, [loading, reload, fleet]);

	// If there is an error, display it
	if (error) return <ClearFleetError error={error} />;

	return <FleetContext.Provider value={value}>{children}</FleetContext.Provider>;
}

export function useFleet(): FleetContextProps {
	const context = useContext(FleetContext);
	if (context === DefaultFleetContext) {
		throw new Error('useFleet must be used within a FleetProvider');
	}

	return context;
}
