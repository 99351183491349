import { Search } from '@mui/icons-material';
import MuiKeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Alert, Grid, IconButton, InputAdornment, Typography, styled } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import StyledDataGrid from 'core/components/DataGrid';
import Dialog from 'core/components/Dialog';
import FormInputField from 'core/components/FormInputField';
import FormSelectField from 'core/components/FormSelectField';
import { useAPI, useToast } from 'core/hooks';
import { useFormik } from 'formik';
import SupplementsService, {
	SupplementsSearchVehiclesRequest,
} from 'modules/irp/modules/supplements/api/SupplementsService';
import Vehicle from 'modules/irp/modules/vehicles/types/Vehicle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LookupValue from 'types/LookupValue';
import * as Yup from 'yup';

interface AddVehicleForm {
	field: '' | 'vin' | 'titleNumber' | 'unitNumber';
	value: string;
	vehicles: Vehicle[];
}

interface AddVehicleDialogProps {
	supplementKey: string;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	searchVehiclesRequest?: Partial<SupplementsSearchVehiclesRequest>;
	onVehiclesAdded?: (vehicles: Vehicle[]) => void;
}

const DataGrid = StyledDataGrid<Vehicle>();
export const KeyboardArrowDown = styled(MuiKeyboardArrowDown)(({ theme }) => ({
	fill: theme.palette.primary.main,
}));

export default function AddVehicleDialog({
	supplementKey,
	isOpen,
	setIsOpen,
	searchVehiclesRequest,
	onVehiclesAdded,
}: AddVehicleDialogProps) {
	// Hooks
	const { t } = useTranslation(['irp/supplements']);
	const supplementsService = useAPI(SupplementsService);
	const { openToast } = useToast();

	// State
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [vehicles, setVehicles] = useState<Vehicle[] | null>(null);

	// Add vehicle form
	const addVehicleForm = useFormik<Pick<AddVehicleForm, 'vehicles'>>({
		initialValues: {
			vehicles: [],
		},
		validationSchema: Yup.object().shape({
			vehicles: Yup.array().min(1),
		}),
		validateOnMount: true,
		onReset: () => {
			addVehicleForm.validateForm();
		},
		onSubmit: (data) => {
			setIsSaving(true);

			// Submit all vehicles
			return Promise.all(data.vehicles.map((vehicle) => supplementsService.addVehicle(supplementKey, vehicle.key)))
				.then((newVehicles) => {
					// eslint-disable-next-line no-use-before-define
					handleClose();
					addVehicleForm.resetForm();

					openToast({
						id: `add-vehicle-dialog:${newVehicles.map((v) => v.key).join(',')}`,
						message: t('dialogs.add_vehicle.added'),
						severity: 'success',
					});
					if (onVehiclesAdded) onVehiclesAdded(newVehicles);
				})
				.finally(() => setIsSaving(false));
		},
	});

	// Search form
	const searchForm = useFormik<Omit<AddVehicleForm, 'vehicles'>>({
		initialValues: {
			field: '',
			value: '',
		},
		validationSchema: Yup.object().shape({
			field: Yup.string().required(t('data.validation.required', { ns: 'core' })),
			value: Yup.string().required(t('data.validation.required', { ns: 'core' })),
		}),
		onReset: () => {
			setVehicles(null);
		},
		onSubmit: ({ field, value }) => {
			addVehicleForm.setFieldValue('vehicles', []);

			setIsLoading(true);
			supplementsService
				.searchVehicles(supplementKey, {
					...searchVehiclesRequest,
					[field]: value,
				})
				.then((resp) => {
					setVehicles(resp);

					// Select only vehicle, or clear selection if multiple
					addVehicleForm.setFieldValue('vehicles', resp.length === 1 ? resp : []);
				})
				.finally(() => setIsLoading(false));
		},
	});

	const handleClose = () => {
		setIsOpen(false);

		// Wait for modal close
		setTimeout(() => {
			addVehicleForm.resetForm();
			searchForm.resetForm();
		}, 250);
	};

	const handleCriteriaKeyDown = (e: React.KeyboardEvent) => {
		// Alpha-numeric only
		if (!/^[a-zA-Z0-9]*$/.test(e.key)) {
			e.preventDefault();
		}

		// Enter only
		if (e.key !== 'Enter') return;
		searchForm.submitForm();
	};

	const handleSubmit = async () => {
		// Not search, proxy to search
		if (vehicles === null) return searchForm.submitForm();

		const errors = await addVehicleForm.validateForm();
		if (Object.keys(errors).length > 0) return undefined;

		return addVehicleForm.submitForm();
	};

	// Data
	const columns: GridColDef<Vehicle>[] = [
		{ headerName: t('vehicle.vin', { ns: 'data' }), field: 'vin', minWidth: 200, flex: 1 },
		{
			headerName: t('vehicle.unitNumber', { ns: 'data' }),
			field: 'unitNumber',
			minWidth: 50,
		},
		{
			headerName: t('vehicle.title.number', { ns: 'data' }),
			field: 'titleNumber',
			minWidth: 125,
			renderCell: ({ row }) => <span>{row.title.number}</span>,
		},
		{
			headerName: t('vehicle.plate.number', { ns: 'data' }),
			field: 'plateNumber',
			minWidth: 125,
			renderCell: ({ row }) => row.plate?.number || <span>&mdash;</span>,
		},
	];
	const options: LookupValue[] = [
		{ id: 1, code: 'vin', displayName: t('vehicle.vin', { ns: 'data' }) },
		{ id: 2, code: 'unitNumber', displayName: t('vehicle.unitNumber', { ns: 'data' }) },
		{ id: 3, code: 'titleNumber', displayName: t('vehicle.title.number', { ns: 'data' }) },
	];

	return (
		<Dialog
			title={t('dialogs.add_vehicle.title')}
			isOpen={isOpen}
			setIsOpen={handleClose}
			confirmLabel={vehicles === null ? t('buttons.search', { ns: 'core' }) : t('dialogs.add_vehicle.title')}
			onConfirm={handleSubmit}
			maxWidth="sm"
		>
			<Typography paragraph>{t('dialogs.add_vehicle.subheading')}:</Typography>

			<form name="addVehicleForm" noValidate onSubmit={searchForm.handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<FormSelectField
							name="searchType"
							label={t('dialogs.add_vehicle.search_type')}
							helperText={searchForm.errors.field || t('data.validation.required', { ns: 'core' })}
							options={options}
							value={options.find((v) => v.code === searchForm.values.field) || null}
							getOptionLabel={(v) => v.displayName}
							getOptionKey={(v) => v.id}
							onChange={(v) => searchForm.setFieldValue('field', v?.code || null)}
							slotProps={{
								formControl: { error: searchForm.touched.field && !!searchForm.errors.field },
								autocomplete: {
									disabled: isLoading || isSaving,
									disableClearable: true,
								},
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						<FormInputField
							name="searchCriteria"
							label={t('dialogs.add_vehicle.search_criteria')}
							helperText={searchForm.errors.value || t('data.validation.required', { ns: 'core' })}
							slotProps={{
								formControl: { error: searchForm.touched.value && !!searchForm.errors.value },
								input: {
									value: searchForm.values.value,
									disabled: isLoading || isSaving,
									onKeyDown: handleCriteriaKeyDown,
									onChange: (e) => searchForm.setFieldValue('value', e.target.value),
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="search"
												edge="end"
												color="primary"
												disabled={isLoading || isSaving}
												onClick={searchForm.submitForm}
											>
												<Search />
											</IconButton>
										</InputAdornment>
									),
								},
							}}
						/>
					</Grid>
				</Grid>
			</form>

			{/* Results */}
			{vehicles !== null && (
				<Grid container spacing={2} mt={1}>
					<Grid item xs={12}>
						{vehicles.length > 0 ? (
							<Alert severity="success">{t('dialogs.add_vehicle.results.found', { count: vehicles.length })}</Alert>
						) : (
							<Alert severity="error">{t('dialogs.add_vehicle.results.none')}</Alert>
						)}
					</Grid>
					{vehicles.length > 0 && (
						<Grid item xs={12}>
							<DataGrid
								columns={columns}
								rows={vehicles}
								getRowId={(row) => row.key}
								pageSizeOptions={[5, 10, 20]}
								initialState={{
									pagination: { paginationModel: { pageSize: 5 } },
								}}
								rowSelectionModel={
									addVehicleForm.values.vehicles ? addVehicleForm.values.vehicles.map((v) => v.key) : undefined
								}
								isRowSelectable={() => !isLoading}
								onRowClick={({ id }) => {
									const selection = vehicles.filter((v) => id === v.key);
									addVehicleForm.setFieldValue('vehicles', selection);
								}}
								loading={isLoading}
								disableRowSelectionOnClick={isSaving}
							/>
						</Grid>
					)}
				</Grid>
			)}
		</Dialog>
	);
}
