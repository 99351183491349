import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ProgressPercentageProps {
	progress?: number;
}

export default function ProgressPercentage({ progress = 0 }: ProgressPercentageProps) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
			<Box sx={{ width: '100%', mr: 2 }}>
				<LinearProgress variant="determinate" value={progress} sx={{ height: 4 }} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(progress)}%`}</Typography>
			</Box>
		</Box>
	);
}
