import { LoadingButton as MuiLoadingButton, LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { usePermissions } from 'core/hooks';
import { Action } from 'core/types/permissions';
import { PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LoadingButtonProps extends MuiLoadingButtonProps {
	onClick: () => void | Promise<void>;

	// Permission required to perform the action
	permission?: { resource: string; action: Action };

	// Hide the button entirely if the user does not have permission
	hideNoPermission?: boolean;
}

export default function LoadingButton({
	children,
	onClick,
	permission,
	hideNoPermission,
	...props
}: PropsWithChildren<LoadingButtonProps>) {
	// Hooks
	const { canAccess } = usePermissions();
	const { t } = useTranslation();

	// State
	const [loading, setLoading] = useState(false);

	// Computed
	const hasPermission = permission ? canAccess(permission.resource, permission.action) : true;

	const handleClick = useCallback(async () => {
		setLoading(true);
		try {
			await onClick();
		} finally {
			setLoading(false);
		}
	}, [onClick]);

	// Hide if no permission
	if (hideNoPermission && !hasPermission) return null;

	return (
		<Tooltip title={!hasPermission ? t('errors.403.toast', { ns: 'core' }) : ''}>
			{/*
			Extra div to allow tooltips for disabled buttons
			https://mui.com/material-ui/react-tooltip/#disabled-elements
			 */}
			<span>
				<MuiLoadingButton
					loading={loading}
					onClick={handleClick}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
					disabled={!hasPermission || props.disabled}
				>
					{children}
				</MuiLoadingButton>
			</span>
		</Tooltip>
	);
}
