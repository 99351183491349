import { CanAccess } from 'core/components';
import Redirect from 'core/components/Redirect';
import { Route } from 'core/types/routing';
import SupplementPageContainer from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import DeleteVehicleSteps from 'modules/irp/modules/supplements/modules/delete_vehicle/components/DeleteSteps';
import SupplementBlocker from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { lazy, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Permissions from 'types/Permissions';
import DeleteDetailsStep from './DeleteDetailsStep';
import DeleteDocumentsStep from './DeleteDocumentsStep';
import DeleteSubmitStep from './DeleteSubmitStep';
import DeleteVerifyStep from './DeleteVerifyStep';
import DeleteVehiclePaths from './paths';

const VehicleDetailsRoute = lazy(
	() => import('modules/irp/modules/supplements/modules/delete_vehicle/routes/VehicleDetailsRoute'),
);
const VehicleDetailsCredentials = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsCredentials'),
);
const VehicleDetailsDocuments = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsDocuments'));
const VehicleDetailsPlate = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPlate'));
const VehicleDetailsPurchase = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPurchase'));
const VehicleDetailsRegistration = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsRegistration'),
);

function DeleteVehicleIndex() {
	const { supplementKey } = useTypedParams(DeleteVehiclePaths);
	return <Redirect to={DeleteVehiclePaths.Details.buildPath({ supplementKey })} replace />;
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/delete_vehicle');
	return (
		<SupplementBlocker
			basePath={DeleteVehiclePaths}
			title={t('dialogs.close.title', { ns: 'irp/supplements/delete_vehicle' })}
		>
			{children}
		</SupplementBlocker>
	);
}

// Route component mappings
const DeleteVehicleRoutes = [
	{
		path: DeleteVehiclePaths.path,
		element: (
			<CanAccess
				resource={Permissions.IRP.Vehicles.resource}
				action={Permissions.IRP.Vehicles.actions.delete}
				showError
			>
				<SupplementPageContainer
					breadcrumbs={['irp/supplements/delete_vehicle', 'irp/supplements', 'irp/vehicles']}
					stepRoutes={[
						DeleteVehiclePaths.Details.path,
						DeleteVehiclePaths.Documentation.path,
						DeleteVehiclePaths.Verify.path,
						DeleteVehiclePaths.Submit.path,
					]}
					steps={<DeleteVehicleSteps />}
				>
					<WithBlocker>
						<Outlet />
					</WithBlocker>
				</SupplementPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'irp/supplements/delete_vehicle' }),
		},
		children: [
			// Index route
			{
				path: DeleteVehiclePaths.path,
				index: true,
				element: <DeleteVehicleIndex />,
			},

			// Steps
			{
				path: DeleteVehiclePaths.Details.path,
				element: <DeleteDetailsStep />,
			},
			{
				path: DeleteVehiclePaths.Documentation.path,
				element: <DeleteDocumentsStep />,
			},
			{
				path: DeleteVehiclePaths.Verify.path,
				element: <DeleteVerifyStep />,
			},
			{
				path: DeleteVehiclePaths.Submit.path,
				element: <DeleteSubmitStep />,
			},

			// Vehicle info
			{
				path: DeleteVehiclePaths.Vehicle.path,
				element: <VehicleDetailsRoute />,
				handle: {
					crumb: ({ t }) => t('details.title', { ns: 'irp/vehicles' }),
				},
				children: [
					{
						path: DeleteVehiclePaths.Vehicle.path,
						index: true,
						element: <VehicleDetailsPlate />,
					},
					{
						path: DeleteVehiclePaths.Vehicle.Credentials.path,
						element: <VehicleDetailsCredentials />,
					},
					{
						path: DeleteVehiclePaths.Vehicle.Purchase.path,
						element: <VehicleDetailsPurchase />,
					},
					{
						path: DeleteVehiclePaths.Vehicle.Documents.path,
						element: <VehicleDetailsDocuments />,
					},
					{
						path: DeleteVehiclePaths.Vehicle.Registration.path,
						element: <VehicleDetailsRegistration />,
					} as Route<typeof DeleteVehiclePaths.Vehicle.Registration.path>,
				],
			} as Route<typeof DeleteVehiclePaths.Vehicle>,
		],
	} as Route<typeof DeleteVehiclePaths>,
];

export default DeleteVehicleRoutes;
