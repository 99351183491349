import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from 'core/components/Dialog';
import FormDateField from 'core/components/FormDateField';
import FormSelectField from 'core/components/FormSelectField';
import { useAPI, useToast } from 'core/hooks';
import { Dayjs } from 'dayjs';
import { getIn, useFormik } from 'formik';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import DeleteVehiclePaths from 'modules/irp/modules/supplements/modules/delete_vehicle/routes/paths';
import Vehicle, { VehicleFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Date, { DateValidationSchema, DateValidations, dayjsToDate } from 'types/Date';
import LookupValue from 'types/LookupValue';
import * as Yup from 'yup';

export type EditVehiclesForm = {
	deactivate: {
		date?: Date;
		reason?: LookupValue;
	};
	return?: LookupValue;
};

interface EditVehiclesDialogProps extends Pick<DialogProps, 'isOpen' | 'setIsOpen'> {
	vehicles: Vehicle[];
	onVehiclesEdited: () => void;
	deactivateReasons: LookupValue[];
	plateReturnOptions?: LookupValue[];
}

export default function EditVehiclesDialog({
	vehicles,
	isOpen,
	setIsOpen,
	onVehiclesEdited,
	deactivateReasons,
	plateReturnOptions,
}: EditVehiclesDialogProps) {
	const { t } = useTranslation(['irp/supplements/delete_vehicle', 'irp/supplements']);
	const { openToast } = useToast();

	const supplementsService = useAPI(SupplementsService);
	const { supplementKey } = useTypedParams(DeleteVehiclePaths);

	const [isSaving, setIsSaving] = useState<boolean>(false);

	const { values, setFieldValue, ...formik } = useFormik<EditVehiclesForm>({
		initialValues: {
			deactivate: {},
		},
		validationSchema: Yup.object()
			.shape({
				deactivate: Yup.object().shape({
					date: DateValidationSchema.default(undefined).test('futureDate', DateValidations.noFutureDate(t)),
					reason: Yup.object(),
				}),
				return: Yup.object(),
			})
			.test('required', (data, context) => {
				if (
					(data.deactivate.date?.year && data.deactivate.date?.month && data.deactivate.date?.day) ||
					data.deactivate.reason ||
					data.return
				)
					return true;

				return context.createError({
					path: 'atLeastOneField',
				});
			}),
		onSubmit: async (data) => {
			setIsSaving(true);

			const updatedVehicles = vehicles.map((vehicle) => {
				const v: VehicleFields = {};
				if (data.deactivate?.date) {
					v.deactivate = {
						date: data.deactivate.date,
					};
				}
				if (data.deactivate?.reason) {
					v.deactivate = {
						...v.deactivate,
						reason: data.deactivate.reason,
					};
				}
				if (data.return) {
					v.plate = {
						return: data.return,
					};
				}

				return supplementsService.updateVehicle(supplementKey, vehicle.key, v);
			});

			return Promise.all(updatedVehicles)
				.then(() => {
					formik.resetForm();
					onVehiclesEdited();
				})
				.finally(() => setIsSaving(false));
		},
	});

	const handleDeactivateDateChanged = (value: Dayjs | null) => {
		if (!value || !value.isValid()) return;
		setFieldValue('deactivate.date', dayjsToDate(value));
	};

	const handleSubmit = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: `irp/supplements/delete_vehicle/edit/incomplete`,
				message: t('dialogs.edit_vehicle.error', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleClose = () => {
		if (setIsOpen) setIsOpen(false);
		// Wait for dialog close
		setTimeout(() => formik.resetForm(), 250);
	};

	return (
		<Dialog
			title={t('dialogs.edit_vehicle.title', { ns: 'irp/supplements', count: vehicles.length })}
			isOpen={isOpen}
			onConfirm={handleSubmit}
			confirmLabel={t('buttons.save', { ns: 'core' })}
			setIsOpen={handleClose}
			maxWidth="sm"
		>
			<Typography paragraph>{t('dialogs.edit_vehicle.subheading', { ns: 'irp/supplements' })}</Typography>
			<form name="bulkEditVehicleForm" noValidate>
				<Grid container pt={1} spacing={2}>
					{plateReturnOptions && (
						<Grid item xs={4}>
							<FormDateField
								name="deactivate.date"
								label={t('vehicle.deactivateDate', { ns: 'data' })}
								slotProps={{
									formControl: { error: !!getIn(formik.errors, 'atLeastOneField') || !!formik.errors.deactivate?.date },
									datePicker: {
										onChange: handleDeactivateDateChanged,
										disabled: isSaving,
										disableFuture: true,
										slotProps: {
											field: {
												clearable: false,
											},
										},
									},
								}}
							/>
						</Grid>
					)}
					<Grid item xs={4}>
						<FormSelectField
							name="deactivateReason"
							label={t('vehicle.deactivateReason', { ns: 'data' })}
							value={deactivateReasons.find((x) => x.id === values.deactivate.reason?.id) || null}
							options={deactivateReasons}
							onChange={(v) => setFieldValue('deactivate.reason', v)}
							getOptionKey={(v) => v.id}
							getOptionLabel={(v) => v.displayName}
							slotProps={{
								formControl: { error: !!getIn(formik.errors, 'atLeastOneField') },
								autocomplete: {
									disabled: isSaving,
								},
							}}
						/>
					</Grid>
					{plateReturnOptions && (
						<Grid item xs={4}>
							<FormSelectField
								name="plateReturn"
								label={t('vehicle.plate.return', { ns: 'data' })}
								value={plateReturnOptions.find((x) => x.id === values.return?.id) || null}
								options={plateReturnOptions}
								onChange={(v) => setFieldValue('return', v)}
								getOptionKey={(v) => v.id}
								getOptionLabel={(v) => v.displayName}
								slotProps={{
									formControl: { error: !!getIn(formik.errors, 'atLeastOneField') },
									autocomplete: {
										disabled: isSaving,
									},
								}}
							/>
						</Grid>
					)}
				</Grid>
			</form>
		</Dialog>
	);
}
