import { Error, RemoveCircle, Report, Warning } from '@mui/icons-material';
import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ClearFleetErrorProps {
	// Error to display
	error: Error | { title: string; messages: string[] };
}

export default function ClearFleetError({ error }: ClearFleetErrorProps) {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();

	// Determine title
	const title: string = (() => {
		// Object
		if ('title' in error && typeof error.title === 'string') {
			return error.title;
		}

		// API Errors
		if ('statusCode' in error && typeof error.statusCode === 'number') {
			if (i18n.exists(`errors.${error.statusCode}.title`)) return t(`errors.${error.statusCode}.title`);
			return t('errors.default.title');
		}

		// Default
		return t('errors.general.title');
	})();

	// Determine message(s)
	const messages: string[] = (() => {
		// Object
		if ('messages' in error && error.messages instanceof Array) {
			return error.messages;
		}

		// Single message
		if ('message' in error && typeof error.message === 'string') {
			return [error.message];
		}

		// Default
		return [t('errors.general.message')];
	})();

	// Determine icon
	const icon: JSX.Element = (() => {
		const iconStyle = { color: theme.palette.error.main, fontSize: 128 };

		// Status code
		if ('statusCode' in error && typeof error.statusCode === 'number') {
			switch (error.statusCode) {
				case 400:
					return <Error sx={iconStyle} />;
				case 403:
					return <RemoveCircle sx={iconStyle} />;
				case 404:
					return <Report sx={iconStyle} />;
				default:
					return <Warning sx={iconStyle} />;
			}
		}

		// Default
		return <Warning sx={iconStyle} />;
	})();

	return (
		<Container>
			{icon}

			<Typography variant="h2">{title}</Typography>

			<Box maxWidth="33vw" my={2}>
				{(messages || []).map((msg) => (
					<Typography key={msg} variant="h5" textAlign="center" lineHeight={1.5} paragraph>
						{msg}
					</Typography>
				))}
			</Box>

			<Button variant="contained" onClick={() => navigate('/')}>
				{t('buttons.return_home', { ns: 'core' })}
			</Button>
		</Container>
	);
}

const Container = styled(Box)(({ theme }) => ({
	margin: theme.spacing(2),
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: theme.spacing(3),
}));
