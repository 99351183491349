enum Program {
	IFTA = 'PROGRAM_IFTA',
	IRP = 'PROGRAM_IRP',
}

export default Program;

export enum IftaStatus {
	Active = 'A',
}

export function programName(program: Program): string {
	return program.replace(/^PROGRAM_/i, '');
}

export enum IrpStatus {
	Active = '100',
	Pending = 'New',
}
