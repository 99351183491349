import { CanAccess } from 'core/components';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import { FleetProvider } from 'modules/irp/modules/fleets/providers/FleetProvider';
import SupplementPageContainer, {
	SupplementContainerContext,
} from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import RenewalAddVehicleContainer from 'modules/irp/modules/supplements/modules/renewal/components/RenewalAddVehicleContainer';
import RenewalSteps from 'modules/irp/modules/supplements/modules/renewal/components/RenewalSteps';
import RenewalSupplementCreator from 'modules/irp/modules/supplements/modules/renewal/components/RenewalSupplementCreator';
import SupplementBlocker, { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import SupplementPaths from 'modules/irp/modules/supplements/routes/paths';
import { WeightGroupProvider } from 'modules/irp/modules/weight_groups/providers/WeightGroupProvider';
import { lazy, PropsWithChildren, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { FleetIncludes } from 'types/Fleet';
import Permissions from 'types/Permissions';
import { WeightGroupIncludes, WeightGroupNew } from 'types/WeightGroup';
import RenewalPaths from './paths';
import RenewalDetailsStep from './RenewalDetailsStep';

const RenewalFleetStep = lazy(() => import('./RenewalFleetStep'));
const RenewalFleetDetails = lazy(() => import('./RenewalFleetDetails'));
const RenewalFleetJurisdictions = lazy(() => import('./RenewalFleetJurisdictions'));
const RenewalFleetInfo = lazy(() => import('./RenewalFleetInfo'));

const RenewalWeightGroupsStep = lazy(() => import('./RenewalWeightGroupsStep'));
const RenewalWeightGroupDetails = lazy(() => import('./RenewalWeightGroupDetails'));
const RenewalWeightGroupInfo = lazy(() => import('./RenewalWeightGroupInfo'));

const RenewalVehiclesStep = lazy(() => import('./RenewalVehiclesStep'));
const RenewalAddVehicleOptions = lazy(() => import('./RenewalAddVehicleOptions'));
const RenewalAddVehicleInfo = lazy(() => import('./RenewalAddVehicleInfo'));
const RenewalAddVehicleWeightGroup = lazy(() => import('./RenewalAddVehicleWeightGroup'));
const RenewalAddVehicleRegistration = lazy(() => import('./RenewalAddVehicleRegistration'));

const RenewalDocumentsStep = lazy(() => import('./RenewalDocumentsStep'));
const RenewalVerifyStep = lazy(() => import('./RenewalVerifyStep'));
const RenewalSubmitStep = lazy(() => import('./RenewalSubmitStep'));

// View Vehicle Detail
const VehicleDetailsRoute = lazy(() => import('./RenewalVehiclesView'));
const VehicleDetailsPlate = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPlate'));
const VehicleDetailsCredentials = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsCredentials'),
);
const VehicleDetailsPurchase = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPurchase'));
const VehicleDetailsDocuments = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsDocuments'));
const VehicleDetailsRegistration = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsRegistration'),
);

// Vehicle changes
const VehicleChangesRoute = lazy(() => import('./RenewalVehicleChanges'));
const VehicleChangesInformation = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleChangesInformation'),
);
const VehicleChangesWeightGroup = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleChangesWeightGroup'),
);
const VehicleChangesRegistration = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleChangesRegistration'),
);

function WeightGroupContainer({ children }: PropsWithChildren) {
	const { supplementKey } = useTypedParams(RenewalPaths);
	const value = useMemo(() => ({ closePath: RenewalPaths.WeightGroups.buildPath({ supplementKey }) }), [supplementKey]);

	return <SupplementContainerContext.Provider value={value}>{children}</SupplementContainerContext.Provider>;
}

function WeightGroupProviderFromPath({ children }: PropsWithChildren) {
	const params = useTypedParams(RenewalPaths.WeightGroups.WeightGroup.Info);
	const include: WeightGroupIncludes = useMemo(() => ({ fleet: true, operatingJurisdictions: true }), []);

	return (
		<WeightGroupProvider weightGroupKey={params.weightGroupKey} include={include}>
			{children}
		</WeightGroupProvider>
	);
}

function FleetProviderFromSupplement({ children }: PropsWithChildren) {
	const { supplement } = useSupplement();
	const include: FleetIncludes = useMemo(() => ({ mileage: true }), []);
	return (
		<FleetProvider fleetKey={supplement?.fleet?.key || ''} includes={include}>
			{children}
		</FleetProvider>
	);
}

function RenewalIndex() {
	const { supplementKey } = useTypedParams(RenewalPaths);
	return <Redirect to={RenewalPaths.Registration.buildPath({ supplementKey })} replace />;
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/renewal');
	return (
		<SupplementBlocker basePath={RenewalPaths} title={t('dialogs.close.title')}>
			{children}
		</SupplementBlocker>
	);
}

const FleetRoutes = [
	// Fleet details
	{
		path: RenewalPaths.Fleet.path,
		element: (
			<FleetProviderFromSupplement>
				<WithBlocker>
					<Outlet />
				</WithBlocker>
			</FleetProviderFromSupplement>
		),
		handle: {
			crumb: ({ t }) => t('irp/fleets:details.title'),
		},
		children: [
			{
				index: true,
				path: RenewalPaths.Fleet.path,
				element: <RenewalFleetStep />,
			},

			// Fleet information (read-only)
			{
				path: RenewalPaths.Fleet.Info.path,
				element: <RenewalFleetInfo />,
				handle: {
					crumb: ({ t }) => t('buttons.view', { ns: 'core' }),
				},
			} as Route<typeof RenewalPaths.Fleet>,
		],
	} as Route<typeof RenewalPaths.Fleet>,

	// Fleet details form
	{
		path: RenewalPaths.Fleet.Details.path,
		element: <RenewalFleetDetails />,
		handle: {
			crumb: ({ t }) => t('irp/fleets:details.title'),
		},
	} as Route<typeof RenewalPaths.Fleet>,

	// Fleet jurisdictions form
	{
		path: RenewalPaths.Fleet.Jurisdictions.path,
		element: <RenewalFleetJurisdictions />,
		handle: {
			crumb: ({ t }) => t('irp/fleets:details.title'),
		},
	} as Route<typeof RenewalPaths.Fleet>,
];

const WeightGroupRoutes = {
	path: RenewalPaths.WeightGroups.path,
	element: <Outlet />,
	handle: {
		// TODO where does this click back to?
		crumb: ({ t }) => t('irp/weight_groups:title'),
	},
	children: [
		{
			index: true,
			path: RenewalPaths.WeightGroups.path,
			element: (
				<WithBlocker>
					<RenewalWeightGroupsStep />
				</WithBlocker>
			),
		} as Route<typeof RenewalPaths.WeightGroups>,

		// Weight Group add/edit
		{
			path: RenewalPaths.WeightGroups.WeightGroup.path,
			element: (
				<WeightGroupContainer>
					<RenewalWeightGroupDetails />
				</WeightGroupContainer>
			),
			handle: {
				crumb: ({ t, params }) =>
					t(`buttons.${params.weightGroupKey === WeightGroupNew ? 'add' : 'edit'}`, { ns: 'core' }),
			},
		} as Route<typeof RenewalPaths.WeightGroups.WeightGroup>,

		// Weight Group view info
		{
			path: RenewalPaths.WeightGroups.WeightGroup.Info.path,
			element: (
				<WeightGroupProviderFromPath>
					<RenewalWeightGroupInfo />
				</WeightGroupProviderFromPath>
			),
			handle: {
				crumb: ({ t }) => t('buttons.view', { ns: 'core' }),
			},
		} as Route<typeof RenewalPaths.WeightGroups.WeightGroup.Info>,
	],
} as Route<typeof RenewalPaths.WeightGroups>;

const VehicleRoutes = {
	path: RenewalPaths.Vehicles.path,
	element: <Outlet />,
	handle: {
		crumb: ({ t }) => t('irp/vehicles:title'),
	},
	children: [
		{
			index: true,
			path: RenewalPaths.Vehicles.path,
			element: (
				<WithBlocker>
					<RenewalVehiclesStep />
				</WithBlocker>
			),
		} as Route<typeof RenewalPaths.Vehicles>,

		// Vehicle add/edit
		{
			path: RenewalPaths.Vehicles.Vehicle.path,
			element: (
				<RenewalAddVehicleContainer>
					<Outlet />
				</RenewalAddVehicleContainer>
			),
			handle: {
				crumb: ({ t }) => t('irp/vehicles:details.title'),
			},
			children: [
				// Index route
				{
					path: '',
					index: true,
					element: <RenewalAddVehicleOptions />,
				} as Route<typeof RenewalPaths.Vehicles.Vehicle>,
				{
					path: RenewalPaths.Vehicles.Vehicle.Info.path,
					element: <RenewalAddVehicleInfo />,
				},
				{
					path: RenewalPaths.Vehicles.Vehicle.WeightGroup.path,
					element: <RenewalAddVehicleWeightGroup />,
				},
				{
					path: RenewalPaths.Vehicles.Vehicle.Registration.path,
					element: <RenewalAddVehicleRegistration />,
				} as Route<typeof RenewalPaths.Vehicles.Vehicle.Registration>,
			],
		} as Route<typeof RenewalPaths.Vehicles.Vehicle>,

		// Vehicle View
		{
			path: RenewalPaths.Vehicles.Vehicle.View.path,
			handle: {
				crumb: ({ t }) => t('buttons.view', { ns: 'core' }),
			},
			element: <VehicleDetailsRoute />,
			children: [
				{
					path: RenewalPaths.Vehicles.Vehicle.View.path,
					index: true,
					element: <VehicleDetailsPlate />,
				},
				{
					path: RenewalPaths.Vehicles.Vehicle.View.Credentials.path,
					element: <VehicleDetailsCredentials />,
				},
				{
					path: RenewalPaths.Vehicles.Vehicle.View.Purchase.path,
					element: <VehicleDetailsPurchase />,
				},
				{
					path: RenewalPaths.Vehicles.Vehicle.View.Documents.path,
					element: <VehicleDetailsDocuments />,
				},
				{
					path: RenewalPaths.Vehicles.Vehicle.View.Registration.path,
					element: <VehicleDetailsRegistration />,
				} as Route<typeof RenewalPaths.Vehicles.Vehicle.View.Registration>,
			],
		} as Route<typeof RenewalPaths.Vehicles.Vehicle.View>,

		// Vehicle Changes
		{
			path: RenewalPaths.Vehicles.Vehicle.Changes.path,
			handle: {
				crumb: ({ t }) => t('changes.title', { ns: 'irp/vehicles' }),
			},
			element: <VehicleChangesRoute />,
			children: [
				{
					path: RenewalPaths.Vehicles.Vehicle.Changes.path,
					index: true,
					element: (
						<VehicleChangesInformation showRequestNewPlate ignoreFields={['registration.temporaryAuthorityNeeded']} />
					),
				},
				{
					path: RenewalPaths.Vehicles.Vehicle.Changes.WeightGroup.path,
					element: <VehicleChangesWeightGroup />,
				},
				{
					path: RenewalPaths.Vehicles.Vehicle.Changes.Registration.path,
					element: <VehicleChangesRegistration />,
				} as Route<typeof RenewalPaths.Vehicles.Vehicle.Changes.Registration>,
			],
		} as Route<typeof RenewalPaths.Vehicles.Vehicle.Changes>,
	],
} as Route<typeof RenewalPaths.Vehicles>;

// Route component mappings
const RenewalRoutes = [
	{
		path: RenewalPaths.path,
		element: (
			<CanAccess resource={Permissions.IRP.resource} action={Actions.READ} showError>
				<SupplementPageContainer
					breadcrumbs={[
						'irp/supplements/renewal',
						'irp/supplements',
						'irp/fleets',
						'irp/weight_groups',
						'irp/vehicles',
						'accounts',
					]}
					stepRoutes={[
						RenewalPaths.Registration.path,
						RenewalPaths.Fleet.path,
						RenewalPaths.Fleet.Details.path,
						RenewalPaths.Fleet.Jurisdictions.path,
						RenewalPaths.WeightGroups.path,
						RenewalPaths.Vehicles.path,
						RenewalPaths.Documentation.path,
						RenewalPaths.Verify.path,
						RenewalPaths.Submit.path,
					]}
					steps={<RenewalSteps />}
					creator={<RenewalSupplementCreator />}
				>
					<ErrorBoundary fallback={<Redirect to={SupplementPaths.buildPath({})} />}>
						<Outlet />
					</ErrorBoundary>
				</SupplementPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('irp/supplements/renewal:title'),
		},
		children: [
			// Index route
			{
				path: RenewalPaths.path,
				index: true,
				element: <RenewalIndex />,
			},

			// Registration details
			{
				path: RenewalPaths.Registration.path,
				element: (
					<WithBlocker>
						<RenewalDetailsStep />
					</WithBlocker>
				),
			} as Route<typeof RenewalPaths>,

			...FleetRoutes,
			WeightGroupRoutes,
			VehicleRoutes,

			// Documentation
			{
				path: RenewalPaths.Documentation.path,
				element: (
					<WithBlocker>
						<RenewalDocumentsStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('irp/supplements:documentation.title'),
				},
			} as Route<typeof RenewalPaths.Documentation>,

			// Verify
			{
				path: RenewalPaths.Verify.path,
				element: (
					<WithBlocker>
						<RenewalVerifyStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('irp/supplements:verify.title'),
				},
			} as Route<typeof RenewalPaths.Verify>,

			// Submit
			{
				path: RenewalPaths.Submit.path,
				element: (
					<WithBlocker>
						<RenewalSubmitStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('irp/supplements:submit.title'),
				},
			} as Route<typeof RenewalPaths.Submit>,
		],
	} as Route<typeof RenewalPaths>,
];

export default RenewalRoutes;
