import { useToast } from 'core/hooks';
import { SupplementDocumentTypes } from 'modules/documents/types/Document';
import SupplementDocumentsForm from 'modules/irp/modules/supplements/components/SupplementDocumentsForm';
import { VehicleDocumentationTableType } from 'modules/irp/modules/supplements/components/VehicleDocumentationTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { SupplementType } from 'types/Supplement';
import DeleteVehiclePaths from './paths';

export default function DeleteDocumentsStep() {
	// Hooks
	const { supplementKey } = useTypedParams(DeleteVehiclePaths);
	const { t } = useTranslation(['irp/supplements']);
	const navigate = useNavigate();
	const { openToast } = useToast();

	// State
	const [validDeleteDocuments, setValidDeleteDocuments] = useState<boolean>(false);

	const handleNext = () => {
		if (!validDeleteDocuments) {
			openToast({
				id: 'irp/delete_vehicle/documents',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
			return;
		}
		navigate(DeleteVehiclePaths.Verify.buildPath({ supplementKey }));
	};

	return (
		<SupplementDocumentsForm
			previousPath={DeleteVehiclePaths.Details.buildPath({ supplementKey })}
			documentTypes={SupplementDocumentTypes[SupplementType.DeleteVehicle]}
			tableType={VehicleDocumentationTableType.Delete}
			validator={setValidDeleteDocuments}
			onSubmit={handleNext}
		/>
	);
}
