import { Alert, Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import SkeletonField from 'core/components/SkeletonField';
import TitleDivider from 'core/components/TitleDivider';
import { phoneNumberFormat } from 'core/services/intl';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import { useCarrier } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { SupplementType } from 'types/Supplement';
import RenewalPaths from './paths';

interface AccountInformationField {
	label: string;
	value: string | number | undefined;
}

interface InformationGridFactoryProps {
	title?: string;
	fields: AccountInformationField[];
}

function InformationGridFactory({ title, fields }: InformationGridFactoryProps) {
	return (
		<Box mb={3}>
			{title && (
				<Typography variant="h4" gutterBottom my={3}>
					{title}
				</Typography>
			)}
			<Grid container rowGap={2}>
				{fields.map(({ label, value }) => {
					return (
						<Grid item xs={6} md={4} key={label}>
							<Typography variant="subtitle2" gutterBottom>
								{label}
							</Typography>
							<SkeletonField value={value} />
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
}

export default function RenewalDetailsStep() {
	// Hooks
	const { t } = useTranslation('irp/supplements/renewal');
	const navigate = useNavigate();
	const { carrier } = useCarrier();
	const { supplement } = useSupplement<SupplementType.Renewal>();
	const { supplementKey } = useTypedParams(RenewalPaths);

	// Wait for data loading
	if (!carrier || !supplement) return SupplementContentSkeleton;
	if (!supplement.details.renewal.irpRegistration) return SupplementContentSkeleton;

	const { irpRegistration } = supplement.details.renewal;

	const handleNext = async () => {
		navigate(RenewalPaths.Fleet.Details.buildPath({ supplementKey }));
	};

	const { einTin, dotNumber, accountFlags } = carrier;

	const accountInformationFields: AccountInformationField[] = [
		{ label: t('carrier.ein_tin', { ns: 'data' }), value: einTin },
		{ label: t('carrier.usdot', { ns: 'data' }), value: dotNumber },
	];

	const accountFlagFields: AccountInformationField[] = [
		{
			label: t('carrier.registrant_only.title', { ns: 'data' }),
			value: accountFlags.registrantOnly ? t('data.yes', { ns: 'core' }) : t('data.no', { ns: 'core' }),
		},
		{
			label: t('carrier.destroy_plates.title', { ns: 'data' }),
			value: accountFlags.destroyPlates ? t('data.yes', { ns: 'core' }) : t('data.no', { ns: 'core' }),
		},
		{
			label: t('carrier.use_first_operated_date.title', { ns: 'data' }),
			value: accountFlags.useFirstOperatedDate ? t('data.yes', { ns: 'core' }) : t('data.no', { ns: 'core' }),
		},
	];

	const { name, dbaName } = carrier;
	const { contact } = irpRegistration;

	const businessInformationFields: AccountInformationField[] = [
		{ label: t('carrier.business_name', { ns: 'data' }), value: name },
		{ label: t('carrier.dba_name', { ns: 'data' }), value: dbaName },
		{ label: t('carrier.contact_name', { ns: 'data' }), value: contact.name },
		{ label: t('carrier.contact_email', { ns: 'data' }), value: contact.email },
		{
			label: t('carrier.phone', { ns: 'data' }),
			value:
				phoneNumberFormat(contact.phone || '') + (contact.phoneExtension ? ` x${contact.phoneExtension}` : '') || '',
		},
		{ label: t('carrier.fax', { ns: 'data' }), value: contact.fax ? phoneNumberFormat(contact.fax) : '\u2014' },
	];

	const { business } = irpRegistration.addresses;

	const businessAddressFields: AccountInformationField[] = [
		{ label: t('data.fields.street1', { ns: 'core' }), value: business?.line1 },
		{ label: t('data.fields.street2', { ns: 'core' }), value: business?.line2 },
		{ label: t('data.fields.county', { ns: 'core' }), value: business?.county },
		{ label: t('data.fields.city', { ns: 'core' }), value: business?.city },
		{ label: t('data.fields.state', { ns: 'core' }), value: business?.state?.name },
		{ label: t('data.fields.zip', { ns: 'core' }), value: business?.postalCode },
	];

	const { mailing } = irpRegistration.addresses;

	const mailingAddressFields: AccountInformationField[] = [
		{ label: t('data.fields.street1', { ns: 'core' }), value: mailing?.line1 },
		{ label: t('data.fields.street2', { ns: 'core' }), value: mailing?.line2 },
		{ label: t('data.fields.county', { ns: 'core' }), value: mailing?.county },
		{ label: t('data.fields.city', { ns: 'core' }), value: mailing?.city },
		{ label: t('data.fields.state', { ns: 'core' }), value: mailing?.state?.name },
		{ label: t('data.fields.zip', { ns: 'core' }), value: mailing?.postalCode },
	];

	return (
		<Box display="flex" flexDirection="column" rowGap={3}>
			<Card>
				<CardContent>
					<Typography variant="h3" gutterBottom mb={2}>
						{t('renewal.title')}
					</Typography>

					<TitleDivider />

					<Alert severity="info" sx={{ mt: 2 }}>
						{t('renewal.renewal_info_change')}
					</Alert>

					<InformationGridFactory
						title={t('details.account_information', { ns: 'accounts' })}
						fields={accountInformationFields}
					/>

					<InformationGridFactory fields={accountFlagFields} />

					<Divider />

					<InformationGridFactory
						title={t('details.business_information', { ns: 'accounts' })}
						fields={businessInformationFields}
					/>

					<Divider />

					<InformationGridFactory
						title={t('details.business_address', { ns: 'accounts' })}
						fields={businessAddressFields}
					/>

					{mailing && (
						<>
							<Divider />
							<InformationGridFactory
								title={t('details.mailing_address', { ns: 'accounts' })}
								fields={mailingAddressFields}
							/>
						</>
					)}
				</CardContent>
			</Card>

			<Box>
				<SupplementStepFooter nextLabel={t('buttons.next', { ns: 'core' })} onNext={handleNext} />
			</Box>
		</Box>
	);
}
