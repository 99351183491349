import ApiService from 'core/services/api';
import { DocumentType } from 'modules/documents/types/Document';
import LookupValue from 'types/LookupValue';

export default class DocumentsService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/documents`);
	}

	async deleteDocument(id: string): Promise<void> {
		await this.DELETE(`/${id}`);
	}

	async downloadDocument(id: string, quiet?: boolean): Promise<string> {
		const { url } = await this.GET<{ url: string }>(`/${id}/download`, {
			quiet,
		});
		return url;
	}

	async getDocumentStatuses(): Promise<LookupValue[]> {
		const path = '/config/statuses';
		const { statuses } = await this.GET<{ statuses: LookupValue[] }>(path);

		return statuses;
	}
}

export interface UploadRequest {
	document: {
		name: string;
		contentType: string;
		size: number;
		documentType: DocumentType;
	};
}

export interface UploadResponse {
	// Document ID
	id: string;

	// Storage service upload URL to PUT the file
	url: string;

	// Metadata to send with the PUT request as headers
	metadata: Record<string, string>;
}
