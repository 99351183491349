import RouteStepper, { RouteStepperProps } from 'core/components/RouteStepper';
import EditFleetPaths from 'modules/irp/modules/supplements/modules/edit_fleet/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

export default function EditFleetSteps() {
	const { t } = useTranslation(['irp/supplements/edit_fleet', 'irp/supplements']);

	const { supplementKey } = useTypedParams(EditFleetPaths);

	const steps: RouteStepperProps['steps'] = [
		{
			label: t('details.step'),
			route: EditFleetPaths.Details.buildPath({ supplementKey }),
		},
		{ label: t('verify.step', { ns: 'irp/supplements' }), route: EditFleetPaths.Verify.buildPath({ supplementKey }) },
		{ label: t('submit.step', { ns: 'irp/supplements' }), route: EditFleetPaths.Submit.buildPath({ supplementKey }) },
	];

	return <RouteStepper steps={steps} />;
}
