import { useTransmittal } from 'modules/transmittals/providers/TransmittalProvider';
import TransmittalPaths from 'modules/transmittals/routes/paths';
import { TransmittalStep } from 'modules/transmittals/types/Transmittal';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { programName } from 'types/Program';

type StepRoute = {
	buildPath: (params: { program: string; id: number }) => string;
};

const steps: Record<TransmittalStep, StepRoute> = {
	[TransmittalStep.Started]: TransmittalPaths.Program.Edit.Confirm, // Redirect to the next step
	[TransmittalStep.Confirmed]: TransmittalPaths.Program.Edit.Test, // Redirect to the next step
	[TransmittalStep.Testing]: TransmittalPaths.Program.Edit.Production, // Redirect to the next step
	[TransmittalStep.Production]: TransmittalPaths.Program.Edit.Complete, // Redirect to the next step
	[TransmittalStep.Remitted]: TransmittalPaths.Program.Edit.Complete,
};

export default function TransmittalStepRedirector() {
	const { transmittal } = useTransmittal();
	const navigate = useNavigate();

	// Redirect to the current step
	useEffect(() => {
		const path = steps[transmittal?.step];
		if (!path) return;

		navigate(path.buildPath({ program: programName(transmittal.program), id: transmittal.id }), { replace: true });
	}, [transmittal, navigate]);

	return null;
}
