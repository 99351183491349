import { Box, Typography } from '@mui/material';
import { Loader } from 'core/components';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import RenewalPaths from 'modules/irp/modules/supplements/modules/renewal/routes/paths';
import { useEffect, useRef } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { SupplementNew } from 'types/Supplement';

export interface SupplementCreatorProps {
	message: string;
	create: () => void;
}

export default function SupplementCreator({ message, create }: SupplementCreatorProps) {
	const { supplementKey } = useTypedParams(RenewalPaths);
	const creating = useRef(false); // Use a reference to bypass double rendering in strict mode

	// New supplement, create it
	useEffect(() => {
		if (supplementKey !== SupplementNew || creating.current) return;
		creating.current = true;

		// Create the supplement
		create();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supplementKey]);

	// Existing supplement, load using skeleton
	if (supplementKey !== SupplementNew) return SupplementContentSkeleton;

	// New supplement, create
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			gap={2}
			height={875}
			maxHeight="50vh"
		>
			<Loader />
			<Typography variant="h5">{message}</Typography>
		</Box>
	);
}
