import Grid from '@mui/material/Grid';
import { Breadcrumbs, PageContainer } from 'core/components';
import { useTitle } from 'core/hooks';
import { useRazor } from 'core/providers/RazorProvider';
import AccountLookup from 'modules/accounts/components/AccountLookup';
import CarrierSupplementInfo from 'modules/irp/modules/supplements/components/CarrierSupplementInfo';

import { CarrierProvider } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export default function FleetsPageContainer({ children }: PropsWithChildren) {
	// Hooks
	const { t } = useTranslation('irp/fleets');
	const { accountKey } = useRazor();

	useTitle(`${t('title')} - ${t('title', { ns: 'core' })}`);

	// No account key provided, ask for it
	if (accountKey === null) {
		return <AccountLookup />;
	}

	return (
		<CarrierProvider carrierKey={accountKey}>
			<PageContainer>
				<Breadcrumbs ns="irp/fleets" />

				<Grid container spacing={3} wrap="wrap">
					<Grid sx={{ order: { xs: 3, xl: 2 } }} item xs={12} xl={9}>
						{children}
					</Grid>

					<Grid sx={{ order: { xs: 1, xl: 3 } }} item xs={12} xl={3}>
						<CarrierSupplementInfo />
					</Grid>
				</Grid>
			</PageContainer>
		</CarrierProvider>
	);
}
