import { GridColDef } from '@mui/x-data-grid';
import { DataGridColumnHeader } from 'core/components/DataGrid';
import { currencyFormat } from 'core/services/intl';
import { InvoiceVehicle } from 'modules/irp/modules/vehicles/api/Invoice';
import { useTranslation } from 'react-i18next';

export type ColumnHeaders = 'base' | 'foreign' | 'admin' | 'ifta' | 'total' | 'refund' | 'plateFee';

export default function SupplementFeesColumns(columns: ColumnHeaders[]): GridColDef<InvoiceVehicle>[] {
	// Hooks
	const { t } = useTranslation('irp/supplements');

	const standardColumns: GridColDef<InvoiceVehicle>[] = [
		{
			headerName: t('vehicle.vin', { ns: 'data' }),
			field: 'vin',
			minWidth: 170,
			flex: 1,
		},
		{
			headerName: t('vehicle.unitNumber', { ns: 'data' }),
			field: 'unitNumber',
			minWidth: 100,
			flex: 1,
		},
		{
			headerName: t('vehicle.title.number', { ns: 'data' }),
			field: 'titleNumber',
			minWidth: 80,
			flex: 1,
		},
	];

	const baseFees: GridColDef<InvoiceVehicle> = {
		renderHeader: () => (
			<DataGridColumnHeader
				label={t('verify.fees.base')}
				tooltip={t('verify.tooltips.baseFees')}
				sx={{
					textAlign: 'center',
					width: 112,
				}}
			/>
		),
		field: 'fees.base',
		minWidth: 160,
		valueGetter: ({ row }) => row.fees?.base,
		renderCell: ({ value }) => (value ? currencyFormat(value) : <span>&mdash;</span>),
	};

	const foreignFees: GridColDef<InvoiceVehicle> = {
		renderHeader: () => (
			<DataGridColumnHeader
				label={t('verify.fees.foreign')}
				tooltip={t('verify.tooltips.foreignFees')}
				sx={{
					textAlign: 'center',
					width: 112,
				}}
			/>
		),
		field: 'fees.foreign',
		minWidth: 160,
		valueGetter: ({ row }) => row.fees?.foreign,
		renderCell: ({ value }) => (value ? currencyFormat(value) : <span>&mdash;</span>),
	};

	const adminFees: GridColDef<InvoiceVehicle> = {
		renderHeader: () => (
			<DataGridColumnHeader
				label={t('verify.fees.admin')}
				tooltip={t('verify.tooltips.adminFees')}
				sx={{
					textAlign: 'center',
					width: 112,
				}}
			/>
		),
		field: 'fees.admin',
		minWidth: 160,
		valueGetter: ({ row }) => row.fees?.admin,
		renderCell: ({ value }) => (value ? currencyFormat(value) : <span>&mdash;</span>),
	};

	const iftaDecalFees: GridColDef<InvoiceVehicle> = {
		headerName: t('verify.fees.iftaDecals', { ns: 'irp/supplements' }),
		field: 'fees.iftaDecal',
		minWidth: 130,
		valueGetter: ({ row }) => row.fees?.iftaDecal,
		renderCell: ({ value }) => (value ? currencyFormat(value) : <span>&mdash;</span>),
	};

	const totalFees: GridColDef<InvoiceVehicle> = {
		renderHeader: () => (
			<DataGridColumnHeader
				label={t('data.total', { ns: 'core' })}
				tooltip={t('verify.tooltips.total')}
				sx={{
					textAlign: 'center',
				}}
			/>
		),
		field: 'fees.total',
		minWidth: 120,
		valueGetter: ({ row }) => row.fees?.total,
		renderCell: ({ value }) => (value ? currencyFormat(value) : <span>&mdash;</span>),
	};

	const estimatedRefund: GridColDef<InvoiceVehicle> = {
		headerName: t('verify.fees.estimatedRefund'),
		renderHeader: () => (
			<DataGridColumnHeader
				label={t('verify.fees.estimatedRefund')}
				tooltip={t('verify.tooltips.estimatedRefund')}
				sx={{
					textAlign: 'center',
				}}
			/>
		),
		field: 'fees.estimatedRefund',
		align: 'right',
		headerAlign: 'right',
		width: 195,
		valueGetter: ({ row }) => row.fees?.estimatedRefund,
		renderCell: ({ value }) => (value ? currencyFormat(value * -1) : <span>&mdash;</span>),
	};

	const nonReturnedPlateFee: GridColDef<InvoiceVehicle> = {
		renderHeader: () => (
			<DataGridColumnHeader
				label={t('verify.fees.nonReturnedPlateFee')}
				tooltip={t('verify.tooltips.nonReturnedPlateFee')}
				sx={{
					textAlign: 'center',
				}}
			/>
		),
		field: 'fees.nonReturnedPlateFee',
		align: 'right',
		headerAlign: 'right',
		width: 220,
		valueGetter: ({ row }) => row.fees?.nonReturnedPlateFee,
		renderCell: ({ value }) => (value ? currencyFormat(value) : <span>&mdash;</span>),
	};

	const columnMap = {
		base: baseFees,
		foreign: foreignFees,
		admin: adminFees,
		ifta: iftaDecalFees,
		total: totalFees,
		refund: estimatedRefund,
		plateFee: nonReturnedPlateFee,
	};

	return [...standardColumns, ...columns.map((c) => columnMap[c])];
}
