import { route, string } from 'react-router-typesafe-routes/dom';

const UserPaths = route(
	'admin/users',
	{},
	{
		Create: route('new'),
		User: route(
			':key',
			{ params: { key: string() } },
			{
				Edit: route('edit'),
			},
		),
		Permissions: route(
			'permissions',
			{},
			{
				Index: route(''),
				Legacy: route('legacy'),
			},
		),
	},
);

export default UserPaths;
