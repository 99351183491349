import { Skeleton, Typography, TypographyProps } from '@mui/material';
import { dateFormat } from 'core/services/intl';

export interface SkeletonFieldProps {
	value?: string | number | Date | null;
	loading?: boolean;
	variant?: TypographyProps['variant'];
}

export default function SkeletonField({ loading, value, variant }: SkeletonFieldProps) {
	// Loading
	if (loading) {
		return (
			<Typography variant={variant || 'body2'}>
				<Skeleton
					sx={{
						display: 'flex',
						flex: 1,
						transform: 'unset',
					}}
				/>
			</Typography>
		);
	}

	// Apply formatting depending on the type
	let v = value;
	if (value instanceof Date) {
		v = dateFormat(value);
	}

	// Decide if the value is empty
	const isEmpty = v === undefined || v === null || (typeof v === 'string' && v.trim() === '');

	// Loaded
	return <Typography variant={variant || 'body2'}>{isEmpty ? <span>&mdash;</span> : String(v)}</Typography>;
}
