import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from 'core/components/Dialog';
import FormDateField from 'core/components/FormDateField';
import { useAPI, useToast } from 'core/hooks';
import { dayjsFromObject } from 'core/services/intl';
import dayjs, { Dayjs } from 'dayjs';
import { getIn, useFormik } from 'formik';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import TransferVehiclePaths from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/paths';
import Vehicle, {
	VehicleFeeCalculationDate,
	VehicleFields,
	VehicleTransfer,
} from 'modules/irp/modules/vehicles/types/Vehicle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Date, { DateValidationSchema, DateValidations } from 'types/Date';
import * as Yup from 'yup';

export type EditVehiclesForm = {
	feeCalculationDate: Date | null;
};

interface EditVehiclesDialogProps extends Pick<DialogProps, 'isOpen' | 'setIsOpen'> {
	startDate: Dayjs | undefined;
	vehicles: VehicleTransfer[];
	onVehiclesEdited: () => void;
}

export default function EditVehicleDialog({
	startDate,
	vehicles,
	isOpen,
	setIsOpen,
	onVehiclesEdited,
}: EditVehiclesDialogProps) {
	// Hooks
	const { t } = useTranslation('irp/supplements/transfer_vehicle');
	const { openToast } = useToast();
	const { supplementKey } = useTypedParams(TransferVehiclePaths);
	const futureRegYear = dayjs().isBefore(startDate);

	// Services
	const supplementsService = useAPI(SupplementsService);

	// State
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [hasTransferVehicles, setHasTransferVehicles] = useState<boolean>(false);

	// Form validation
	const validationSchema = Yup.object()
		.shape({
			feeCalculationDate: DateValidationSchema.default(null).test('minDate', DateValidations.minDate(t)).nullable(),
		})
		.test('required', (data, context) => {
			if (!hasTransferVehicles) return true;

			// At least one field is required if there are vehicles to be edited
			if (hasTransferVehicles && data.feeCalculationDate) return true;

			return context.createError({
				path: 'atLeastOneField',
			});
		});

	// Form
	const { values, setFieldValue, ...formik } = useFormik<EditVehiclesForm>({
		initialValues: {
			feeCalculationDate: null,
		},
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			const vehicleUpdateRequests = vehicles.reduce((requests: Promise<Vehicle>[], vehicle: VehicleTransfer) => {
				if (!vehicle.transferVehicle || !vehicle.transferVehicle.registration) return requests;

				const { feeCalculationDate } = vehicle.transferVehicle.registration;

				let transferVehicleFields: VehicleFields = {};
				// Set fee calculation date
				switch (feeCalculationDate?.code) {
					case VehicleFeeCalculationDate.Purchase:
						transferVehicleFields = {
							purchase: {
								date: data.feeCalculationDate,
							},
						};
						break;
					case VehicleFeeCalculationDate.FirstOperated:
						transferVehicleFields = {
							registration: {
								firstOperatedDate: data.feeCalculationDate,
							},
						};
						break;
					case VehicleFeeCalculationDate.Lease:
						transferVehicleFields = {
							registration: {
								leaseDate: data.feeCalculationDate,
							},
						};
						break;
					case VehicleFeeCalculationDate.Other:
						transferVehicleFields = {
							registration: {
								otherDate: data.feeCalculationDate,
							},
						};
						break;
					default:
						break;
				}

				if (data.feeCalculationDate)
					requests.push(
						supplementsService.updateVehicle(supplementKey, vehicle.transferVehicle.key, transferVehicleFields),
					);

				return requests;
			}, []);

			return Promise.all(vehicleUpdateRequests)
				.then(() => {
					formik.resetForm();
					onVehiclesEdited();
				})
				.finally(() => setIsSaving(false));
		},
	});

	const handleSubmit = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: `irp/supplements/transfer_vehicle/edit/incomplete`,
				message: t('dialogs.edit_vehicle.error', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleClose = () => {
		if (setIsOpen) setIsOpen(false);
		// Wait for dialog close
		setTimeout(() => formik.resetForm(), 250);
	};

	useEffect(() => {
		if (vehicles.some((v) => v.transferVehicle)) setHasTransferVehicles(true);
	}, [vehicles]);

	if (!hasTransferVehicles) {
		return (
			<Dialog
				title={t('dialogs.edit_vehicle.title', { ns: 'irp/supplements', count: vehicles.length })}
				isOpen={isOpen}
				onConfirm={handleSubmit}
				confirmLabel={t('buttons.continue', { ns: 'core' })}
				setIsOpen={handleClose}
				maxWidth="sm"
			>
				{t('dialogs.edit_vehicle.noTransferVehicles')}
			</Dialog>
		);
	}

	return (
		<Dialog
			title={t('dialogs.edit_vehicle.title', { ns: 'irp/supplements', count: vehicles.length })}
			isOpen={isOpen}
			onConfirm={handleSubmit}
			confirmLabel={t('buttons.save', { ns: 'core' })}
			setIsOpen={handleClose}
			maxWidth="sm"
		>
			<Typography paragraph>{t('dialogs.edit_vehicle.subheading')}</Typography>
			<form name="bulkEditVehicleForm" noValidate>
				<Grid container pt={1} spacing={2}>
					<Grid item xs={4}>
						<FormDateField
							name="feeCalculationDate"
							label={t('vehicle.registration.feeCalculationDate', { ns: 'data' })}
							helperText={formik.errors.feeCalculationDate}
							slotProps={{
								formControl: {
									error: !!formik.errors.feeCalculationDate || !!getIn(formik.errors, 'atLeastOneField'),
									disabled: isSaving,
								},
								datePicker: {
									value: dayjsFromObject(values.feeCalculationDate),
									disableFuture: !futureRegYear,
									minDate: startDate?.startOf('month'),
									maxDate: startDate?.endOf('month'),
									disabled: isSaving,
									onChange: (v) => {
										const year = v?.year();
										const month = (v?.month() || 0) + 1;
										const day = v?.date();

										setFieldValue('feeCalculationDate', year && month && day ? { year, month, day } : null);
									},
									slotProps: {
										field: {
											clearable: false,
										},
									},
								},
							}}
						/>
					</Grid>
				</Grid>
			</form>
		</Dialog>
	);
}
