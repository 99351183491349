import RouteStepper, { RouteStepperProps } from 'core/components/RouteStepper';
import AddFleetPaths from 'modules/irp/modules/supplements/modules/add_fleet/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

export default function AddFleetSteps() {
	const { t } = useTranslation(['irp/supplements/add_fleet', 'irp/supplements']);

	const { supplementKey } = useTypedParams(AddFleetPaths);

	const steps: RouteStepperProps['steps'] = [
		{
			label: t('details.step'),
			route: AddFleetPaths.Details.buildPath({ supplementKey }),
			activeRoutes: [AddFleetPaths.Details.Jurisdictions.buildPath({ supplementKey })],
		},
		{
			label: t('weight_groups.step', { ns: 'irp/supplements' }),
			route: AddFleetPaths.WeightGroups.buildPath({ supplementKey }),
		},
		{
			label: t('vehicles.step', { ns: 'irp/supplements' }),
			route: AddFleetPaths.Vehicles.buildPath({ supplementKey }),
		},
		{
			label: t('documentation.step', { ns: 'irp/supplements' }),
			route: AddFleetPaths.Documentation.buildPath({ supplementKey }),
		},
		{ label: t('verify.step', { ns: 'irp/supplements' }), route: AddFleetPaths.Verify.buildPath({ supplementKey }) },
		{ label: t('submit.step', { ns: 'irp/supplements' }), route: AddFleetPaths.Submit.buildPath({ supplementKey }) },
	];

	return <RouteStepper steps={steps} />;
}
