import { route } from 'react-router-typesafe-routes/dom';

const AddVehiclePaths = route(
	'irp/supplements/:supplementKey/add-vehicle',
	{},
	{
		Details: route('details'),
		Documentation: route('documentation'),
		Verify: route('verify'),
		Submit: route('submit'),
		Vehicle: route(
			'vehicles/:vehicleKey',
			{},
			{
				Info: route('info'),
				WeightGroup: route('weight-group'),
				Registration: route('registration'),
			},
		),
	},
);

export default AddVehiclePaths;
