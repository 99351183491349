import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	AlertColor,
	Box,
	Breakpoint,
	Button,
	DialogActions,
	Divider,
	IconButton,
	Dialog as MuiDialog,
	Typography,
	useTheme,
} from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface DialogProps {
	title: string;
	severity?: AlertColor | 'primary';
	maxWidth?: Breakpoint;
	isOpen: boolean;
	setIsOpen?: (isOpen: boolean) => void;
	onConfirm?: () => boolean | void | Promise<void>;
	confirmLabel?: string;
	cancelLabel?: string;
}

function Dialog({
	title,
	severity = 'primary',
	maxWidth = 'xs',
	isOpen,
	setIsOpen,
	onConfirm,
	confirmLabel,
	cancelLabel,
	children,
}: PropsWithChildren<DialogProps>) {
	const { t } = useTranslation();
	const theme = useTheme();

	const [isSaving, setIsSaving] = useState(false);

	const handleClose = () => {
		if (!isSaving && setIsOpen) setIsOpen(false);
	};

	const handleConfirm = async () => {
		setIsSaving(true);
		try {
			if (onConfirm) await onConfirm();
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<MuiDialog open={isOpen} onClose={handleClose} maxWidth={maxWidth} fullWidth>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="flex-start"
				p={3}
				pt={2}
				pb={0}
				borderTop={`0.5rem solid ${theme.palette[severity].main}`}
			>
				<Typography variant="h4">{title}</Typography>
				{(setIsOpen || onConfirm) && (
					<IconButton size="small" sx={{ mt: -0.5, mr: -1 }} onClick={handleClose}>
						<Close />
					</IconButton>
				)}
			</Box>

			<Box sx={{ p: 3, pt: 1 }}>{children}</Box>

			{(setIsOpen || onConfirm) && <Divider sx={{ mt: 1 }} />}

			<DialogActions sx={{ py: 2, justifyContent: 'center', gap: 1 }}>
				{setIsOpen && setIsOpen !== onConfirm && (
					<Button variant="outlined" color={severity} onClick={handleClose}>
						{cancelLabel || t('buttons.cancel', { ns: 'core' })}
					</Button>
				)}

				{onConfirm && (
					<LoadingButton loading={isSaving} variant="contained" color={severity} onClick={handleConfirm} autoFocus>
						{confirmLabel || t('buttons.confirm', { ns: 'core' })}
					</LoadingButton>
				)}
			</DialogActions>
		</MuiDialog>
	);
}

export default Dialog;
