/* eslint-disable max-classes-per-file */
import ApiService from 'core/services/api';
import {
	CreateUserRequest,
	CreateUserResponse,
	PermissionGroups,
	UpdateUserRequest,
	UpdateUserResponse,
	UserRole,
} from 'modules/admin/modules/users/api';
import { GetUserResponse } from 'modules/admin/modules/users/api/GetUser';
import { ListUsersResponse } from 'modules/admin/modules/users/api/ListUsers';
import AccountRoles from 'modules/admin/shared/accountRoles';
import { UserFields, UserType, UserV2 } from 'modules/admin/types/User';

export default class UserService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/users/v1`);
	}

	async list(limit: number, offset?: number): Promise<ListUsersResponse> {
		const path = `?limit=${limit}&offset=${offset || 0}`;
		const response = await this.GET<ListUsersResponse>(path);
		return response;
	}

	async get(userKeyID: string): Promise<GetUserResponse> {
		const path = `/${encodeURIComponent(userKeyID)}`;
		const response = await this.GET<GetUserResponse>(path);
		return response;
	}

	async create(data: CreateUserRequest): Promise<CreateUserResponse> {
		const response = await this.POST<CreateUserRequest, CreateUserResponse>('', data);
		return response;
	}

	async update(data: UpdateUserRequest): Promise<UpdateUserResponse> {
		const path = `/${data.userKeyId}`;
		const response = await this.PUT<UpdateUserRequest, UpdateUserResponse>(path, data);
		return response;
	}

	async getPermissionGroups(accountRole: AccountRoles): Promise<PermissionGroups> {
		const path = `/permissionGroups?role=${accountRole}`;
		return this.GET<PermissionGroups>(path);
	}

	async updatePermissions(
		accountRole: AccountRoles,
		permissions: { id: number; viewOnly: boolean; fullAccess: boolean }[],
	): Promise<void> {
		const path = `/permissionGroups/${accountRole}`;
		return this.PUT(path, { permissions });
	}

	async getRoles(): Promise<UserRole[]> {
		const path = '/roles';
		const { roles } = await this.GET<{ roles: UserRole[] }>(path);

		return roles;
	}

	// v2 API
	v2 = new UserServiceV2();
}

class UserServiceV2 extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/users`);
	}

	async list(query: string, limit?: number, offset?: number): Promise<ListUsersResponseV2> {
		const response = await this.GET<ListUsersResponseV2>('', undefined, {
			params: { query, limit, offset },
		});

		// Transform users
		response.users = response.users.map(UserServiceV2.fromUser);

		return response;
	}

	async get(key: string): Promise<UserV2> {
		const path = `/${encodeURIComponent(key)}`;
		const { user } = await this.GET<{ user: UserV2 }>(path);
		return UserServiceV2.fromUser(user);
	}

	async create(user: UserFields): Promise<UserV2> {
		const resp = await this.POST<{ user: UserFields }, { user: UserV2 }>('', {
			user: UserServiceV2.toUserFields(user),
		});
		return UserServiceV2.fromUser(resp.user);
	}

	async update(key: string, user: UserFields): Promise<void> {
		const path = `/${encodeURIComponent(key)}`;
		return this.PUT<{ user: UserFields }, void>(path, { user });
	}

	async me(): Promise<UserV2> {
		const resp = await this.GET<{ user: UserV2 }>('/me');
		return UserServiceV2.fromUser(resp.user);
	}

	// Map API user type to internal user type
	private static fromUser(user: UserV2): UserV2 {
		// User type mapping
		switch (user.type as string) {
			case 'TYPE_JURISDICTION':
				return { ...user, type: UserType.Jurisdiction };
			case 'TYPE_SERVICE_PROVIDER':
				return { ...user, type: UserType.ServiceProvider };
			case 'TYPE_CARRIER':
				return { ...user, type: UserType.Carrier };
			default:
				return user;
		}
	}

	// Map internal user type to API user type
	private static toUserFields(user: UserFields): UserFields {
		// User type mapping
		switch (user.type) {
			case UserType.Jurisdiction:
				return { ...user, type: 'TYPE_JURISDICTION' as UserType };
			case UserType.ServiceProvider:
				return { ...user, type: 'TYPE_SERVICE_PROVIDER' as UserType };
			case UserType.Carrier:
				return { ...user, type: 'TYPE_CARRIER' as UserType };
			default:
				return user;
		}
	}
}

export interface ListUsersResponseV2 {
	users: UserV2[];
	total: number;
}
