/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import FormControl, { FormControlProps } from 'core/components/FormControl';

interface FormCheckboxFieldProps extends FormControlProps {
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	slotProps?: FormControlProps['slotProps'] & {
		checkbox?: CheckboxProps;
	};
}

export default function FormCheckboxField({
	name,
	label,
	helperText,
	tooltip,
	checked,
	onChange,
	slotProps,
}: FormCheckboxFieldProps) {
	return (
		<FormControl name={name} helperText={helperText} tooltip={tooltip} slotProps={slotProps}>
			<FormControlLabel
				control={<Checkbox {...slotProps?.checkbox} name={name} checked={checked} onChange={onChange} />}
				label={label}
			/>
		</FormControl>
	);
}
