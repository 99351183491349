import { Box, Typography } from '@mui/material';
import { WebStorageStateStore } from 'oidc-client-ts';
import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context';
import ClearFleetError from './ClearFleetError';
import { Loader } from './Loader';

function RequireAuth({ children }: PropsWithChildren) {
	const auth = useAuth();
	const { t } = useTranslation();

	// Automatically sign-in
	useEffect(() => {
		if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
			auth.signinRedirect({ redirect_uri: window.location.href });
		}
	}, [auth]);

	// Silent signin/token refresh
	useEffect(() => {
		return auth.events.addAccessTokenExpiring(() => {
			auth.signinSilent();
		});
	}, [auth]);

	// Display an error message with signin
	function message(msg: string) {
		return (
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<Loader />
				<Typography variant="subtitle1">{msg}</Typography>
			</Box>
		);
	}

	switch (auth.activeNavigator) {
		case 'signinRedirect':
		case 'signinSilent':
			return message(t('auth.signin.in_progress'));
		case 'signoutRedirect':
		case 'signoutSilent':
			return message(t('auth.signout.in_progress'));
		default:
			break;
	}

	// Loading
	if (auth.isLoading) return <Loader fullScreen />;

	// Authentication error
	if (auth.error) {
		return (
			<ClearFleetError
				error={{ title: t('auth.error.title'), messages: [t('auth.error.general'), auth.error.message] }}
			/>
		);
	}

	// Authenticated
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}

export default function AuthorizedApp({ children }: PropsWithChildren) {
	if (!process.env.REACT_APP_AUTH_URL) throw new Error('AUTH_URL is not defined, cannot configure OIDC');
	if (!process.env.REACT_APP_CLIENT_ID) throw new Error('CLIENT_ID is not defined, cannot configure OIDC');

	return (
		<AuthProvider
			{...{
				authority: process.env.REACT_APP_AUTH_URL,
				client_id: process.env.REACT_APP_CLIENT_ID,
				redirect_uri: window.location.origin,
				automaticSilentRenew: true,
				loadUserInfo: true,
				metadataUrl: `${process.env.REACT_APP_AUTH_URL}/o/.well-known/openid-configuration/`,
				onSigninCallback() {
					window.history.replaceState({}, document.title, window.location.pathname);
				},
				metadata: {
					issuer: `${process.env.REACT_APP_AUTH_URL}/o`,
					authorization_endpoint: `${process.env.REACT_APP_AUTH_URL}/o/authorize/`,
					token_endpoint: `${process.env.REACT_APP_AUTH_URL}/o/token/`,
					userinfo_endpoint: `${process.env.REACT_APP_AUTH_URL}/o/userinfo/`,
					jwks_uri: `${process.env.REACT_APP_AUTH_URL}/o/.well-known/jwks.json`,
					end_session_endpoint: `${process.env.REACT_APP_AUTH_URL}/logout/`,
				},
				userStore: new WebStorageStateStore({ store: window.localStorage }),
			}}
		>
			<RequireAuth>{children}</RequireAuth>
		</AuthProvider>
	);
}
