import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { Breadcrumbs, PageContainer } from 'core/components';
import { useTitle } from 'core/hooks';
import { useRazor } from 'core/providers/RazorProvider';
import { useRouteMatch } from 'core/types/routing';
import { FlatNamespace } from 'i18next';
import CarrierSupplementInfo from 'modules/irp/modules/supplements/components/CarrierSupplementInfo';
import { CarrierProvider } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { SupplementContext } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import SupplementPaths from 'modules/irp/modules/supplements/routes/paths';
import RazorPaths from 'modules/razor/paths';
import { createContext, PropsWithChildren, Suspense, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { CarrierIncludeFields } from 'types/Carrier';
import { SupplementNew } from 'types/Supplement';

const DEFAULT_CLOSE_PATH = RazorPaths.Manage.WorkInProcess.buildPath({});

interface SupplementPageContainerProps {
	breadcrumbs: FlatNamespace[];
	steps?: JSX.Element;
	// Step routes determines if the steps should be displayed
	stepRoutes?: string[];
	closePath?: string;
	// Component to handle supplement creation if supplement key is New
	creator?: JSX.Element;
}

// TODO improve with delay to prevent flickering
export const SupplementContentSkeleton = (
	<Box height={875} maxHeight="70vh" width="100%">
		<Skeleton sx={{ WebkitTransform: 'none' }} width="100%" height="100%" />
	</Box>
);

export const CarrierSupplementInfoSkeleton = (
	<Box height={365} maxHeight="70vh" width="100%">
		<Skeleton sx={{ WebkitTransform: 'none' }} width="100%" height="100%" />
	</Box>
);

type SupplementContainerContextProps = {
	closePath: string;
};

const DefaultSupplementContainerContext: SupplementContainerContextProps = {
	closePath: RazorPaths.Manage.WorkInProcess.buildPath({}),
};

export const SupplementContainerContext = createContext<SupplementContainerContextProps>(
	DefaultSupplementContainerContext,
);

export default function SupplementPageContainer({
	breadcrumbs,
	steps,
	stepRoutes,
	closePath,
	creator,
	children,
}: PropsWithChildren<SupplementPageContainerProps>) {
	// Hooks
	const { t } = useTranslation([...breadcrumbs, 'irp/supplements']);
	const supplement = useContext(SupplementContext);
	const { supplementKey } = useTypedParams(SupplementPaths.Supplement);
	const { accountKey } = useRazor();

	useTitle(`${t('title')} - ${t('title', { ns: 'core' })}`);

	const isStepRoute = !!useRouteMatch(stepRoutes || [])?.pattern.path;
	const includes: CarrierIncludeFields = useMemo(() => ({ address: true }), []);
	const value = useMemo(() => ({ closePath: closePath || DEFAULT_CLOSE_PATH }), [closePath]);

	const content = (() => {
		if (supplementKey === SupplementNew) {
			if (creator) return creator;
			throw new Error('No creator provided for new supplement');
		}

		return children;
	})();

	return (
		<CarrierProvider carrierKey={accountKey || ''} includes={includes}>
			<SupplementContainerContext.Provider value={value}>
				<PageContainer>
					<Breadcrumbs ns={breadcrumbs} />

					<Grid container spacing={3} wrap="wrap">
						{steps && isStepRoute && (
							<Grid sx={{ order: { xs: 2, xl: 1 } }} item xs={12} xl={9}>
								{steps}
							</Grid>
						)}

						<Grid sx={{ order: { xs: 3, xl: 2 } }} item xs={12} xl={9} display="flex" flexDirection="column">
							<Suspense fallback={SupplementContentSkeleton}>{content}</Suspense>
						</Grid>

						<Grid sx={{ order: { xs: 1, xl: 3 } }} item xs={12} xl={3}>
							<CarrierSupplementInfo supplement={supplement && supplement?.supplement} />
						</Grid>
					</Grid>
				</PageContainer>
			</SupplementContainerContext.Provider>
		</CarrierProvider>
	);
}

export function useSupplementContainer(): SupplementContainerContextProps {
	const context = useContext(SupplementContainerContext);
	if (context === DefaultSupplementContainerContext) {
		throw new Error('useSupplement must be used within a SupplementPageContainer');
	}

	return context;
}
