export enum UserType {
	Jurisdiction = 'jurisdiction',
	ServiceProvider = 'serviceProvider',
	Carrier = 'carrier',
}

// TODO Update this

export default interface User {
	keyID: string;
	authUserID: number;
	firstName: string;
	lastName: string;
	email: string;
	title: string;
	phoneNumber: string;
	active: boolean;
	userType: UserType;
	roles: string[];
	serviceProvider?: number;
	carrier?: number;
}

// TODO: Remove old User interface
export interface UserV2 {
	id: number;
	key: string;
	authUserId: number;
	firstName: string;
	lastName: string;
	email: string;
	title: string;
	phone: string;
	active: boolean;
	roles: string[];
	type: UserType;
	// TODO: Update this?
	// serviceProvider?: number;
	// carrier?: number;
}

export interface UserFields {
	firstName?: string;
	lastName?: string;
	email?: string;
	title?: string;
	phone?: string;
	active?: boolean;
	type?: UserType;
	roles?: string[];
}
