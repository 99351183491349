/**
 {
    "id": 24138,
    "key": "B2F21852-426C-4DAA-88F9-CF179025057C",
    "name": "FunnyBiz",
    "dotNumber": "4237482",
    "accountNumber": 26133,
    "status": {
      "id": 4011,
      "code": "New",
      "displayName": "New"
    },
    "einTin": "999999999",
    "programStatuses": [],
    "addresses": {
      "account": [
        {
          "id": 352767,
          "type": {
            "id": 113,
            "code": "P",
            "displayName": "P"
          },
          "line1": "123 Biz St",
          "line2": "Suite b",
          "city": "Branson",
          "state": {
            "id": 36,
            "name": "Missouri",
            "code": "MO",
            "countryId": 1,
            "sortOrder": 30,
            "active": true
          },
          "postalCode": "65616",
          "county": "Taney",
          "country": "US"
        },
        {
          "id": 352768,
          "type": {
            "id": 112,
            "code": "M",
            "displayName": "M"
          },
          "line1": "46 Mail St",
          "city": "Hollister",
          "state": {
            "id": 36,
            "name": "Missouri",
            "code": "MO",
            "countryId": 1,
            "sortOrder": 30,
            "active": true
          },
          "postalCode": "65617",
          "country": "US"
        }
      ],
      "irp": [],
      "ifta": []
    },
    "contacts": {
      "account": {
        "name": "Johnny Contact",
        "email": "meh@example.com",
        "phone": "(888) 888-8888",
        "phoneExtension": 5,
        "fax": "(555) 555-5555"
      },
      "irp": null,
      "ifta": null
    },
    "accountFlags": {
      "registrantOnly": false,
      "useFirstOperatedDate": false,
      "destroyPlates": false
    },
    "documents": {
      "recordsOfRetention": {
        "id": 4003,
        "code": "Needed",
        "displayName": "Needed"
      },
      "proofOfResidency": {
        "id": 4003,
        "code": "Needed",
        "displayName": "Needed"
      },
      "carrierApplication": {
        "id": 4003,
        "code": "Needed",
        "displayName": "Needed"
      }
    },
    "tracking": {
      "createdBy": "3B51DD65-E13E-EF11-86C3-6045BD3475CC",
      "createdDate": "2024-09-09T15:29:42.410Z",
      "modifiedBy": "3B51DD65-E13E-EF11-86C3-6045BD3475CC",
      "modifiedDate": "2024-09-09T15:29:42.410Z"
    }
  }
 */

import { AddressType } from 'types/Address';
import Carrier from 'types/Carrier';
import { CreateSupplementRequest, SupplementType } from 'types/Supplement';

export default function newAccountRequestFromCarrier(data: Carrier): CreateSupplementRequest {
	const businessAddress = data.addresses?.account.find((a) => a.type?.code === AddressType.Physical);

	if (!businessAddress) {
		throw new Error('Carrier must have a business address');
	}

	const mailingAddress = data.addresses?.account.find((a) => a.type?.code === AddressType.Mailing);

	return {
		type: SupplementType.NewAccount,
		newAccount: {
			carrier: { id: data.id },
			irpRegistration: {
				contact: {
					name: data.contacts?.account?.name || '',
					email: data.contacts?.account?.email || '',
					phone: data.contacts?.account?.phone || '',
					phoneExtension: data.contacts?.account?.phoneExtension || 0,
					fax: data.contacts?.account?.fax || '',
				},
				addresses: {
					business: {
						line1: businessAddress.line1,
						line2: businessAddress.line2,
						city: businessAddress.city,
						county: businessAddress.county,
						state: { id: businessAddress.state?.id || 0 },
						postalCode: businessAddress.postalCode,
					},
					mailing: mailingAddress
						? {
								line1: mailingAddress.line1,
								line2: mailingAddress.line2,
								city: mailingAddress.city,
								county: mailingAddress.county,
								state: { id: mailingAddress.state?.id || 0 },
								postalCode: mailingAddress.postalCode,
							}
						: undefined,
				},
			},
		},
	};
}
