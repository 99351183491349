import { Loader } from 'core/components';
import ClearFleetError from 'core/components/ClearFleetError';
import { useAPI } from 'core/hooks';
import CarrierService from 'modules/accounts/api/CarriersService';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Carrier, { CarrierIncludeFields } from 'types/Carrier';

type CarrierContextProps = {
	loading: boolean;
	carrier: Carrier | null;
	reload: () => Promise<void>;
};

const DefaultCarrierContext: CarrierContextProps = {
	loading: true,
	carrier: null,
	reload: async () => {
		/* do nothing */
	},
};

const CarrierContext = createContext<CarrierContextProps>(DefaultCarrierContext);

export interface CarrierProviderProps {
	carrierKey: string;
	includes?: CarrierIncludeFields;
}

export function CarrierProvider({ carrierKey, includes, children }: PropsWithChildren<CarrierProviderProps>) {
	// Services
	const carriersService = useAPI(CarrierService);

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [carrier, setCarrier] = useState<Carrier | null>(null);
	const [error, setError] = useState<Error | null>(null);

	const reload = useCallback(async () => {
		if (carrierKey === '') return;

		setLoading(true);
		carriersService
			.get(carrierKey, includes, { quiet: true })
			.then(setCarrier)
			.catch(setError)
			.finally(() => setLoading(false));
	}, [carrierKey, carriersService, includes]);

	// Load data once
	useEffect(() => {
		reload();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const value = useMemo<CarrierContextProps>(() => {
		return { loading, carrier, reload };
	}, [loading, carrier, reload]);

	// Error
	if (error) return <ClearFleetError error={error} />;

	// Loading supplement
	if (loading && !carrier) return <Loader sx={{ flex: 1 }} />;

	return <CarrierContext.Provider value={value}>{children}</CarrierContext.Provider>;
}

export function useCarrier(): CarrierContextProps {
	const context = useContext(CarrierContext);
	if (context === DefaultCarrierContext) {
		throw new Error('useCarrier must be used within a CarrierProvider');
	}

	return context;
}
