import ApiService, { ApiError } from 'core/services/api';
import LookupValue from 'types/LookupValue';
import WeightGroup, { WeightGroupFields, WeightGroupIncludes } from 'types/WeightGroup';

export interface ListWeightGroupsFields {
	accountKey: string;

	supplementKey?: string;
	irpRegistrationYear?: number;
	active?: boolean;
}

export default class WeightGroupsService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/weightGroups`);
	}

	async get(key: string, includes?: WeightGroupIncludes): Promise<WeightGroup> {
		const { weightGroup } = await this.GET<{ weightGroup: WeightGroup }>(`/${key}`, undefined, {
			params: {
				include: includes && Object.keys(includes).length > 0 ? Object.keys(includes).join(',') : undefined,
			},
		});
		return weightGroup;
	}

	async getType(vehicleTypeId: number): Promise<LookupValue> {
		const path = `/config/type`;
		const { weightGroupType } = await this.GET<{ weightGroupType: LookupValue }>(path, undefined, {
			params: { vehicleTypeId },
		});

		return weightGroupType;
	}

	async getTypes(): Promise<LookupValue[]> {
		const path = `/config/types`;
		const { weightGroupTypes } = await this.GET<{ weightGroupTypes: LookupValue[] }>(path, undefined, {});

		return weightGroupTypes;
	}

	async listWeightGroups(fields: ListWeightGroupsFields, includes?: WeightGroupIncludes): Promise<WeightGroup[]> {
		const path = '';
		const { weightGroups } = await this.GET<{ weightGroups: WeightGroup[] }>(path, undefined, {
			params: {
				...fields,
				include: includes && Object.keys(includes).length > 0 ? Object.keys(includes).join(',') : undefined,
			},
		});

		return weightGroups;
	}

	async deactivate(weightGroup: WeightGroup) {
		return this.DELETE(`/${weightGroup.key}/active`);
	}

	async delete(weightGroup: WeightGroup) {
		return this.DELETE(`/${weightGroup.key}`);
	}

	async reactivate(weightGroup: WeightGroup) {
		return this.PUT(`/${weightGroup.key}/active`);
	}

	async update(key: string, weightGroup: WeightGroupFields): Promise<void> {
		return this.PUT(`/${key}`, { weightGroup });
	}

	// Validate one or more weightGroup fields individually
	// This method is used for early erroring of form fields
	async validate(weightGroupKey: string | null, weightGroup: WeightGroupFields, fleetId?: number): Promise<ApiError[]> {
		const path = weightGroupKey ? `/${weightGroupKey}/validate` : '/validate';

		const { errors } = await this.POST<
			{ weightGroup: WeightGroupFields; fleetId?: number },
			{ errors: null | ApiError[] }
		>(path, {
			weightGroup,
			fleetId,
		});
		return errors || [];
	}
}
