import RouteStepper, { RouteStepperProps } from 'core/components/RouteStepper';
import RenewalPaths from 'modules/irp/modules/supplements/modules/renewal/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

export default function RenewalSteps() {
	const { t } = useTranslation([
		'irp',
		'irp/supplements/renewal',
		'irp/supplements',
		'irp/fleets',
		'irp/weight_groups',
	]);

	const { supplementKey } = useTypedParams(RenewalPaths);

	const steps: RouteStepperProps['steps'] = [
		{
			label: t('irp/supplements/renewal:renewal.step'),
			route: RenewalPaths.Registration.buildPath({ supplementKey }),
		},
		{
			label: t('irp/fleets:details.title'),
			route: RenewalPaths.Fleet.buildPath({ supplementKey }),
			activeRoutes: [
				RenewalPaths.Fleet.Details.buildPath({ supplementKey }),
				RenewalPaths.Fleet.Jurisdictions.buildPath({ supplementKey }),
			],
		},
		{ label: t('irp/weight_groups:title'), route: RenewalPaths.WeightGroups.buildPath({ supplementKey }) },
		{ label: t('irp/supplements:vehicles.step'), route: RenewalPaths.Vehicles.buildPath({ supplementKey }) },
		{
			label: t('irp/supplements:documentation.step'),
			route: RenewalPaths.Documentation.buildPath({ supplementKey }),
		},
		{ label: t('irp/supplements:verify.step'), route: RenewalPaths.Verify.buildPath({ supplementKey }) },
		{ label: t('irp/supplements:submit.step'), route: RenewalPaths.Submit.buildPath({ supplementKey }) },
	];

	return <RouteStepper steps={steps} />;
}
