import { Loader } from 'core/components';
import ClearFleetError from 'core/components/ClearFleetError';
import { useAPI } from 'core/hooks';
import UserService from 'modules/admin/modules/users/api/UserService';
import { UserV2 } from 'modules/admin/types/User';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';

// TODO: This needs some love...
type CurrentUserContextProps = {
	currentUser: UserV2;
};

const CurrentUserContext = createContext<CurrentUserContextProps | undefined>(undefined);

export function CurrentUserProvider({ children }: PropsWithChildren) {
	const userService = useAPI(UserService);
	const { isAuthenticated } = useAuth();

	const [currentUser, setCurrentUser] = useState<UserV2>();
	const [error, setError] = useState<Error | null>(null);

	const value = useMemo<CurrentUserContextProps | undefined>(() => {
		if (!currentUser) return undefined;
		return { currentUser };
	}, [currentUser]);

	// Load user
	useEffect(() => {
		if (!isAuthenticated || !!currentUser) return;

		userService.v2.me().then(setCurrentUser).catch(setError);
	}, [isAuthenticated, currentUser, userService]);

	// Error
	if (error) return <ClearFleetError error={error} />;

	// Loading
	if (!currentUser) {
		return <Loader fullScreen />;
	}

	return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
}

export function useCurrentUser() {
	const context = useContext(CurrentUserContext);
	if (context === undefined) {
		throw new Error('useCurrentUser must be used within a CurrentUserProvider');
	}

	return context.currentUser;
}
