import { CanAccess } from 'core/components';
import Redirect from 'core/components/Redirect';
import { Route } from 'core/types/routing';
import SupplementPageContainer from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import EditSteps from 'modules/irp/modules/supplements/modules/edit_vehicle/components/EditSteps';
import EditVehicleContainer from 'modules/irp/modules/supplements/modules/edit_vehicle/components/EditVehicleContainer';
import SupplementBlocker from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { VehicleProvider } from 'modules/irp/modules/vehicles/providers/VehicleProvider';
import { VehicleIncludeFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import { lazy, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Permissions from 'types/Permissions';
import EditDetailsStep from './EditDetailsStep';
import EditVehiclePaths from './paths';

const EditDocumentsStep = lazy(() => import('./EditDocumentsStep'));
const EditVerifyStep = lazy(() => import('./EditVerifyStep'));
const EditSubmitStep = lazy(() => import('./EditSubmitStep'));

const EditVehicleInfoRoute = lazy(() => import('./EditVehicleInfo'));
const EditVehicleWeightGroupRoute = lazy(() => import('./EditVehicleWeightGroup'));
const EditVehicleRegistrationRoute = lazy(() => import('./EditVehicleRegistration'));

// View Vehicle Detail
const VehicleChangesRoute = lazy(() => import('./VehicleChangesRoute'));
const VehicleChangesInformation = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleChangesInformation'),
);
const VehicleChangesWeightGroup = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleChangesWeightGroup'),
);
const VehicleChangesRegistration = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleChangesRegistration'),
);

function EditVehicleIndex() {
	const { supplementKey } = useTypedParams(EditVehiclePaths);
	return <Redirect to={EditVehiclePaths.Details.buildPath({ supplementKey })} replace />;
}

function EditVehicleDetailContainer({ children }: PropsWithChildren) {
	const { vehicleKey } = useTypedParams(EditVehiclePaths.Vehicle.Edit);
	const includes: VehicleIncludeFields = useMemo(() => ({ weightGroup: true }), []);

	return (
		<VehicleProvider vehicleKey={vehicleKey} includes={includes}>
			{children}
		</VehicleProvider>
	);
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/edit_vehicle');
	return (
		<SupplementBlocker
			basePath={EditVehiclePaths}
			title={t('dialogs.close.title', { ns: 'irp/supplements/edit_vehicle' })}
		>
			{children}
		</SupplementBlocker>
	);
}

const EditVehicleRoutes = [
	{
		path: EditVehiclePaths.path,
		element: (
			<CanAccess
				resource={Permissions.IRP.Vehicles.resource}
				action={Permissions.IRP.Vehicles.actions.update}
				showError
			>
				<SupplementPageContainer
					breadcrumbs={[
						'irp/supplements/edit_vehicle',
						'irp/supplements/add_vehicle',
						'irp/supplements',
						'irp/vehicles',
					]}
					stepRoutes={[
						EditVehiclePaths.Details.path,
						EditVehiclePaths.Documentation.path,
						EditVehiclePaths.Verify.path,
						EditVehiclePaths.Submit.path,
					]}
					steps={<EditSteps />}
				>
					<Outlet />
				</SupplementPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'irp/supplements/edit_vehicle' }),
		},
		children: [
			// Index route
			{
				path: EditVehiclePaths.path,
				index: true,
				element: <EditVehicleIndex />,
			},

			// Steps
			{
				path: EditVehiclePaths.Details.path,
				element: (
					<WithBlocker>
						<EditDetailsStep />
					</WithBlocker>
				),
			} as Route<typeof EditVehiclePaths.Details>,

			{
				path: EditVehiclePaths.Documentation.path,
				element: (
					<WithBlocker>
						<EditDocumentsStep />
					</WithBlocker>
				),
			} as Route<typeof EditVehiclePaths.Documentation>,

			{
				path: EditVehiclePaths.Verify.path,
				element: (
					<WithBlocker>
						<EditVerifyStep />
					</WithBlocker>
				),
			} as Route<typeof EditVehiclePaths.Verify>,

			{
				path: EditVehiclePaths.Submit.path,
				element: (
					<WithBlocker>
						<EditSubmitStep />
					</WithBlocker>
				),
			} as Route<typeof EditVehiclePaths.Submit>,

			// Vehicle edit
			{
				path: EditVehiclePaths.Vehicle.path,
				element: (
					<EditVehicleDetailContainer>
						<Outlet />
					</EditVehicleDetailContainer>
				),
				children: [
					{
						path: EditVehiclePaths.Vehicle.Edit.path,
						handle: {
							crumb: ({ t }) => t('vehicle.title', { ns: 'irp/supplements/add_vehicle' }),
						},
						element: (
							<EditVehicleContainer>
								<Outlet />
							</EditVehicleContainer>
						),
						children: [
							{
								path: EditVehiclePaths.Vehicle.Edit.Info.path,
								element: <EditVehicleInfoRoute />,
							},
							{
								path: EditVehiclePaths.Vehicle.Edit.WeightGroup.path,
								element: <EditVehicleWeightGroupRoute />,
							},
							{
								path: EditVehiclePaths.Vehicle.Edit.Registration.path,
								element: <EditVehicleRegistrationRoute />,
							},
						],
					},
				],
			},

			// Vehicle View
			{
				path: EditVehiclePaths.Vehicle.View.path,
				handle: {
					crumb: ({ t }) => t('changes.title', { ns: 'irp/vehicles' }),
				},
				element: <VehicleChangesRoute />,
				children: [
					{
						path: EditVehiclePaths.Vehicle.View.path,
						index: true,
						element: <VehicleChangesInformation />,
					},
					{
						path: EditVehiclePaths.Vehicle.View.WeightGroup.path,
						element: <VehicleChangesWeightGroup />,
					},
					{
						path: EditVehiclePaths.Vehicle.View.Registration.path,
						element: <VehicleChangesRegistration />,
					} as Route<typeof EditVehiclePaths.Vehicle.View.Registration>,
				],
			} as Route<typeof EditVehiclePaths.Vehicle.View>,
		],
	} as Route<typeof EditVehiclePaths>,
];

export default EditVehicleRoutes;
