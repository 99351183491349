import { isEqual, phoneNumberFormat } from 'core/services/intl';
import { Namespace, TFunction } from 'i18next';
import * as Yup from 'yup';
import { Address } from './Address';

export interface Contact {
	name: string;
	email: string;
	phone: string;
	phoneExtension: number | null;
	fax: string | null;
	address?: Address;
}

export enum ContactMethod {
	Unspecified = 'CONTACT_METHOD_UNSPECIFIED',
	Email = 'CONTACT_METHOD_EMAIL',
	Phone = 'CONTACT_METHOD_PHONE',
	Fax = 'CONTACT_METHOD_FAX',
	Mail = 'CONTACT_METHOD_MAIL',
}

export const ContactValidationSchema = ({ t }: { t: TFunction<Namespace> }) => ({
	name: Yup.string().required(t('data.validation.required', { ns: 'core' })),
	phone: Yup.string()
		.matches(/^\(\d{3}\) \d{3}-\d{4}$/, t('data.validation.phone', { ns: 'core' }))
		.required(t('data.validation.required', { ns: 'core' })),
	phoneExtension: Yup.number()
		.nullable()
		.default(null)
		.transform((v) => (!v ? null : v)),
	email: Yup.string()
		.matches(/^(.+)@(.+)\.(.+)$/, t('data.validation.email', { ns: 'core' }))
		.required(t('data.validation.required', { ns: 'core' })),
	fax: Yup.string()
		.nullable()
		.default(null)
		.matches(/^\(\d{3}\) \d{3}-\d{4}$/, t('data.validation.fax', { ns: 'core' })),
});

export const contactsAreEqual = (a?: Contact, b?: Contact): boolean => {
	const f = (c?: Contact) => ({
		name: c?.name || '',
		phone: c?.phone ? phoneNumberFormat(c?.phone) : '',
		phoneExtension: c?.phoneExtension || null,
		email: c?.email || '',
		fax: c?.fax ? phoneNumberFormat(c?.fax) : '',
	});

	return isEqual(f(a), f(b));
};
