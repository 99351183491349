import { Box, Card, CardContent, Typography } from '@mui/material';
import { useAPI, useToast } from 'core/hooks';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import WeightGroupsService from 'modules/irp/modules/weight_groups/api/WeightGroupsService';
import WeightGroupForm, { WeightGroupFormProps } from 'modules/irp/modules/weight_groups/components/WeightGroupForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { SupplementType } from 'types/Supplement';
import WeightGroup, { WeightGroupFields } from 'types/WeightGroup';
import WeightGroupChangePaths from './paths';

export default function WeightGroupChangeDetailsStep() {
	// Hooks
	const { t } = useTranslation(['irp/supplements/weight_group_change', 'irp/weight_groups', 'irp/supplements']);
	const { supplementKey } = useTypedParams(WeightGroupChangePaths);
	const { supplement, reload: reloadSupplement } = useSupplement<SupplementType.WeightGroupChange>();
	const navigate = useNavigate();
	const { openToast } = useToast();
	const weightGroupService = useAPI(WeightGroupsService);

	// State
	const [formik, setFormik] = useState<Parameters<WeightGroupFormProps['onFormik']>[0] | null>(null);

	// Computed
	const weightGroup = ((): WeightGroup | null => {
		const wg = supplement.details.weightGroupChange.weightGroup || null;
		if (!wg) return null;

		return {
			...wg,
			fleet: supplement?.fleet,
		};
	})();

	const handleNext = async () => {
		if (!formik) return undefined;

		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'wgc-details-form-errors',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleSubmit = async (data: WeightGroupFields) => {
		if (!weightGroup || !supplement) return undefined;

		// Update it, toast, reload, and navigate
		return weightGroupService
			.update(weightGroup.key, data)
			.then(() => {
				openToast({
					id: `irp/supplements/weight_group_change/details`,
					message: t(`irp/weight_groups:toasts.updated`),
					severity: 'success',
				});
			})
			.then(() => reloadSupplement())
			.then(() => navigate(WeightGroupChangePaths.Documentation.buildPath({ supplementKey })));
	};

	if (!supplement?.fleet) return SupplementContentSkeleton;

	return (
		<form name="weightGroupChangeForm" noValidate>
			<Box display="flex" flexDirection="column" rowGap={2}>
				<Card>
					<CardContent>
						<Typography variant="h4" gutterBottom mb={2}>
							{t('details.title', { ns: 'irp/weight_groups' })}
						</Typography>

						<WeightGroupForm
							weightGroup={weightGroup || null}
							fleet={supplement.fleet}
							onFormik={setFormik}
							onSubmit={handleSubmit}
						/>
					</CardContent>
				</Card>

				<SupplementStepFooter nextLabel={t('buttons.next', { ns: 'core' })} onNext={handleNext} />
			</Box>
		</form>
	);
}
