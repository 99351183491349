import { Link, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from 'core/components/Dialog';
import FormSelectField from 'core/components/FormSelectField';
import { useAPI, useToast } from 'core/hooks';
import { useFormik } from 'formik';
import i18n from 'i18next';
import { DocumentType } from 'modules/documents/types/Document';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import SupplementPaths from 'modules/irp/modules/supplements/routes/paths';
import Vehicle, { VehicleDocuments } from 'modules/irp/modules/vehicles/types/Vehicle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import LookupValue, { LookupValueValidationSchema } from 'types/LookupValue';
import * as Yup from 'yup';

export interface EditVehiclesForm extends Required<VehicleDocuments> {
	temporaryAuthority: LookupValue | null;
	atLeastOneField?: string;
}

interface EditVehiclesDialogProps extends Pick<DialogProps, 'isOpen' | 'setIsOpen'> {
	vehicles: Vehicle[];
	onVehiclesEdited: () => void;
	documentStatuses: LookupValue[];
	documentTypes: DocumentType[];
	excludeTA?: boolean;
}

interface VehicleDialogFields {
	documents?: Partial<VehicleDocuments>;
	registration?: {
		temporaryAuthorityNeeded?: boolean;
	};
}

export default function EditVehicleDocumentsDialog({
	vehicles,
	isOpen,
	setIsOpen,
	onVehiclesEdited,
	documentStatuses,
	documentTypes,
	excludeTA = false,
}: EditVehiclesDialogProps) {
	// Hooks
	const { t } = useTranslation(['irp/supplements']);
	const { openToast } = useToast();
	const supplementsService = useAPI(SupplementsService);
	const { supplementKey } = useTypedParams(SupplementPaths.Supplement);
	const theme = useTheme();

	// State
	const [isSaving, setIsSaving] = useState<boolean>(false);

	// Options for temporary authority dropdown
	const temporaryAuthorityOptions: LookupValue[] = [
		{ id: 1, code: 'yes', displayName: t('data.yes', { ns: 'core' }) },
		{ id: 2, code: 'no', displayName: t('data.no', { ns: 'core' }) },
	];

	// Form validation
	const validationSchema = Yup.object()
		.shape({
			form2290: Yup.object().shape(LookupValueValidationSchema).nullable(),
			title: Yup.object().shape(LookupValueValidationSchema).nullable(),
			other: Yup.object().shape(LookupValueValidationSchema).nullable(),
			selfCertification: Yup.object().shape(LookupValueValidationSchema).nullable(),
			claimForRefund: Yup.object().shape(LookupValueValidationSchema).nullable(),
			temporaryAuthority: Yup.object().shape(LookupValueValidationSchema).nullable(),
		})
		.test('required', (data, context) => {
			// At least one field is required
			if (
				data.form2290 ||
				data.temporaryAuthority ||
				data.title ||
				data.other ||
				data.claimForRefund ||
				data.selfCertification
			)
				return true;

			return context.createError({
				path: 'atLeastOneField',
			});
		});

	// Form
	const { values, setFieldValue, ...formik } = useFormik<EditVehiclesForm>({
		initialValues: {
			form2290: null,
			title: null,
			other: null,
			selfCertification: null,
			claimForRefund: null,
			temporaryAuthority: null,
		},
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			const updatedVehicles = vehicles.map((v) => {
				const fields: VehicleDialogFields = {};

				// Initialize documents if necessary
				documentTypes.forEach((doc) => {
					if (data[doc]) {
						fields.documents = fields.documents || {};
						fields.documents[doc] = data[doc];
					}
				});

				// Initialize registration if necessary
				if (data.temporaryAuthority) {
					if (!fields.registration) {
						fields.registration = {};
					}
					fields.registration.temporaryAuthorityNeeded = data.temporaryAuthority.code === 'yes';
				}

				return supplementsService.updateVehicle(supplementKey, v.key, fields);
			});

			return Promise.all(updatedVehicles)
				.then(() => {
					formik.resetForm();
					onVehiclesEdited();
				})
				.finally(() => setIsSaving(false));
		},
	});

	const handleSubmit = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: `irp/supplements/bulk-edit/vehicles`,
				message: t('dialogs.edit_vehicle.error', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleClose = () => {
		if (setIsOpen) setIsOpen(false);
		// Wait for dialog close
		setTimeout(() => formik.resetForm(), 250);
	};

	return (
		<Dialog
			title={t('irp/supplements:dialogs.edit_vehicle.title', { count: vehicles.length })}
			isOpen={isOpen}
			onConfirm={handleSubmit}
			confirmLabel={t('buttons.save', { ns: 'core' })}
			setIsOpen={handleClose}
			maxWidth="sm"
		>
			<Typography paragraph>{t('dialogs.edit_vehicle.subheading', { ns: 'irp/supplements' })}</Typography>
			<form name="bulkEditVehicleForm" noValidate>
				<Grid container pt={1} spacing={2}>
					{/* exclude TA if deleted vehicle */}
					{!excludeTA && (
						<Grid item xs={6}>
							<FormSelectField
								name="temporaryAuthority"
								label={t('vehicle.credentials.temporaryAuthority', { ns: 'data' })}
								helperText={formik.errors.temporaryAuthority}
								value={temporaryAuthorityOptions.find((x) => x.id === values.temporaryAuthority?.id) || null}
								options={temporaryAuthorityOptions}
								getOptionKey={(v) => v.id}
								getOptionLabel={(v) => v.displayName}
								onChange={(v) => {
									setFieldValue('temporaryAuthority', v, false);
								}}
								slotProps={{
									formControl: {
										error:
											formik.submitCount > 0 && (!!formik.errors.temporaryAuthority || !!formik.errors.atLeastOneField),
									},
									autocomplete: {
										disabled: isSaving,
									},
								}}
							/>
						</Grid>
					)}
					{documentTypes.map((doc) => (
						<Grid item xs={6} key={doc}>
							<FormSelectField
								name={doc}
								label={t(`vehicle.documents.${doc}.title`, { ns: 'data' })}
								helperText={formik.errors[doc]}
								tooltip={
									i18n.exists(`data:vehicle.documents.${doc}.tooltip`) && (
										<>
											{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
											{/* @ts-ignore */}
											{t(`vehicle.documents.${doc}.tooltip`, { ns: 'data' })}
											{i18n.exists(`data:vehicle.documents.${doc}.link`) && (
												<>
													<br />
													<Link
														color={theme.palette.background.default}
														target="_blank"
														// eslint-disable-next-line @typescript-eslint/ban-ts-comment
														// @ts-ignore
														href={t(`vehicle.documents.${doc}.link`, { ns: 'data' }) as string}
														display="block"
													>
														{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
														{/* @ts-ignore */}
														{t(`vehicle.documents.${doc}.link`, { ns: 'data' })}
													</Link>
												</>
											)}
										</>
									)
								}
								value={documentStatuses.find((x) => x.id === values[doc]?.id) || null}
								options={documentStatuses}
								getOptionKey={(v) => v.id}
								getOptionLabel={(v) => v.displayName}
								onChange={(v) => {
									setFieldValue(doc, v, false);
								}}
								slotProps={{
									formControl: {
										error: formik.submitCount > 0 && (!!formik.errors[doc] || !!formik.errors.atLeastOneField),
									},
									autocomplete: {
										disabled: isSaving,
									},
								}}
							/>
						</Grid>
					))}
				</Grid>
			</form>
		</Dialog>
	);
}
