import { Box, Button, Divider, InputLabel, Typography } from '@mui/material';
import AgencyUseOnlyDivider from 'core/components/AgencyUseOnlyDivider';
import ClearFleetForm, { Field, SelectField } from 'core/components/ClearFleetForm';
import { phoneNumberFormat } from 'core/services/intl';
import { useFormik } from 'formik';
import { useClient } from 'modules/irp/modules/supplements/providers/ClientProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, AddressFields, AddressValidationSchema } from 'types/Address';
import { Contact, ContactValidationSchema } from 'types/Contact';
import IrpRegistration, { IrpRegistrationFields } from 'types/IrpRegistration';
import { State, stateCountyRequired, stateSelectOptions, statesWithDivider } from 'types/State';
import * as Yup from 'yup';

interface IrpRegistrationForm {
	contact: Contact | null;
	addresses: {
		business?: Address | null;
		mailing?: Address | null;
	};
	accountFlags: {
		useFirstOperatedDate: boolean;
		destroyPlates: boolean;
	};
}

export interface IrpRegistrationDetailsFormProps {
	irpRegistration: IrpRegistration;
	onFormik: (formik: ReturnType<typeof useFormik<IrpRegistrationForm>>) => void;
	onSubmit: (data: IrpRegistrationFields) => Promise<void> | void;
}

export default function IrpRegistrationDetailsForm({
	irpRegistration,
	onFormik,
	onSubmit,
}: IrpRegistrationDetailsFormProps) {
	const { t } = useTranslation('accounts');
	const { states } = useClient();

	const businessAddress = irpRegistration?.addresses.business;

	const validationSchema = Yup.object<IrpRegistrationForm>().shape({
		contact: Yup.object()
			.shape(ContactValidationSchema({ t }))
			.required(t('data.validation.required', { ns: 'core' })),
		addresses: Yup.object().shape({
			mailing: Yup.object().shape(AddressValidationSchema({ t })).nullable(),
		}),
		accountFlags: Yup.object().shape({
			useFirstOperatedDate: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
			destroyPlates: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
		}),
	});

	const formik = useFormik<IrpRegistrationForm>({
		initialValues: {
			contact: {
				name: irpRegistration?.contact.name || '',
				email: irpRegistration?.contact.email || '',
				phone: irpRegistration?.contact.phone ? phoneNumberFormat(irpRegistration.contact.phone) : '',
				phoneExtension: irpRegistration?.contact.phoneExtension || null,
				fax: irpRegistration?.contact.fax ? phoneNumberFormat(irpRegistration.contact.fax) : '',
			},
			addresses: {
				mailing: irpRegistration.addresses.mailing || null,
			},
			accountFlags: {
				useFirstOperatedDate: irpRegistration?.accountFlags.useFirstOperatedDate || false,
				destroyPlates: irpRegistration?.accountFlags.destroyPlates || false,
			},
		},
		validationSchema,
		onReset: () => {
			formik.validateForm();
		},
		onSubmit: async (data) => {
			const fields: IrpRegistrationFields = validationSchema.cast(data, { context: data, stripUnknown: true });

			// CLEAR-1905: Clear mailing address
			const submitFields = { ...fields };
			if (submitFields.addresses?.mailing === null) {
				submitFields.addresses.mailing = {} as AddressFields; // Send empty object instead of null to delete address
			}

			return onSubmit(submitFields);
		},
	});

	// Pass form handler up to parent
	useEffect(() => {
		onFormik(formik);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onFormik]);

	const contactInformationFields: Field<IrpRegistrationForm>[] = [
		{
			type: 'text',
			name: 'contact.name',
			label: t('carrier.name', { ns: 'data' }),
			max: 100,
			required: true,
			getValue: (v) => v.contact?.name || '',
		},

		{
			type: 'text',
			name: 'contact.phone',
			label: t('carrier.phone', { ns: 'data' }),
			max: 14,
			required: true,
			getValue: (v) => phoneNumberFormat(v.contact?.phone || ''),
		},

		{
			type: 'number',
			name: 'contact.phoneExtension',
			label: t('carrier.phone_extension', { ns: 'data' }),
			max: 6,
			getValue: (v) => (v.contact?.phoneExtension ? String(v.contact.phoneExtension) : ''),
		},

		{
			type: 'text',
			name: 'contact.email',
			label: t('carrier.email', { ns: 'data' }),
			max: 100,
			required: true,
			getValue: (v) => v.contact?.email || '',
		},

		{
			type: 'text',
			name: 'contact.fax',
			label: t('carrier.fax', { ns: 'data' }),
			max: 14,
			getValue: (v) => phoneNumberFormat(v.contact?.fax || ''),
		},

		{
			type: 'component',
			name: 'business',
			component: businessAddress ? (
				<Box>
					<InputLabel sx={{ mb: 1 }}>{t('fleet.contact.address', { ns: 'data' })}</InputLabel>
					<Typography variant="body2">{businessAddress.line1}</Typography>
					<Typography variant="body2">{businessAddress.line2}</Typography>
					<Typography variant="body2">
						{businessAddress.city}, {businessAddress.state?.code} {businessAddress.postalCode},{' '}
						{businessAddress.country}
					</Typography>
				</Box>
			) : null,
		},
	];

	const mailingAddressFields: Field<IrpRegistrationForm>[] = [
		{
			type: 'text',
			name: 'addresses.mailing.line1',
			label: t('data.fields.street1', { ns: 'core' }),
			max: 100,
			required: true,
			getValue: (v) => v.addresses.mailing?.line1 || '',
		},

		{
			type: 'text',
			name: 'addresses.mailing.line2',
			label: t('data.fields.street2', { ns: 'core' }),
			max: 100,
			getValue: (v) => v.addresses.mailing?.line2 || '',
		},

		{
			type: 'text',
			name: 'addresses.mailing.county',
			label: t('data.fields.county', { ns: 'core' }),
			required: stateCountyRequired(formik.values.addresses.mailing?.state?.code || ''),
			getValue: (v) => v.addresses.mailing?.county || '',
		},

		{
			type: 'text',
			name: 'addresses.mailing.city',
			label: t('data.fields.city', { ns: 'core' }),
			max: 50,
			required: true,
			getValue: (v) => v.addresses.mailing?.city || '',
		},

		{
			type: 'select',
			name: 'addresses.mailing.state',
			label: t('data.fields.state', { ns: 'core' }),
			options: statesWithDivider(states),
			getValue: (v) => v.addresses.mailing?.state || null,
			required: true,
			selectProps: {
				...stateSelectOptions,
				onChange: () => {
					setTimeout(() => {
						formik.validateField('addresses.mailing.county');
					}, 1);
				},
			},
		} as SelectField<IrpRegistrationForm, State>,

		{
			type: 'text',
			name: 'addresses.mailing.postalCode',
			label: t('data.fields.zip', { ns: 'core' }),
			max: 10,
			required: true,
			getValue: (v) => v.addresses.mailing?.postalCode || '',
		},
	];

	const agencyFields: Field<IrpRegistrationForm>[] = [
		{
			type: 'radio',
			name: 'accountFlags.useFirstOperatedDate',
			label: t('carrier.use_first_operated_date.prompt', { ns: 'data' }),
			options: [
				{ label: t('data.yes', { ns: 'core' }), value: 'true' },
				{ label: t('data.no', { ns: 'core' }), value: 'false' },
			],
			getValue: (v) => String(v.accountFlags.useFirstOperatedDate || false),
		},

		{
			type: 'radio',
			name: 'accountFlags.destroyPlates',
			label: t('carrier.destroy_plates.prompt', { ns: 'data' }),
			options: [
				{ label: t('data.yes', { ns: 'core' }), value: 'true' },
				{ label: t('data.no', { ns: 'core' }), value: 'false' },
			],
			getValue: (v) => String(v.accountFlags.destroyPlates || false),
		},
	];

	return (
		<Box>
			<Typography variant="h4" gutterBottom mb={2}>
				{t('details.contact_information')}
			</Typography>

			<ClearFleetForm fields={contactInformationFields} form={formik} loading={formik.isSubmitting} />

			{!formik.values.addresses.mailing && (
				<Box display="flex" columnGap={1} sx={{ mt: 3 }}>
					<Button
						variant="outlined"
						onClick={() => formik.setFieldValue('addresses.mailing', { state: null })}
						disabled={formik.isSubmitting}
					>
						{t('buttons.add_mailing_address', { ns: 'core' })}
					</Button>
				</Box>
			)}

			{!!formik.values.addresses.mailing && (
				<>
					<Divider sx={{ my: 3 }} />
					<Typography variant="h4" gutterBottom mb={2}>
						{t('details.optional_mailing_address')}
					</Typography>
					<ClearFleetForm fields={mailingAddressFields} form={formik} loading={formik.isSubmitting} />
					<Button
						sx={{ mt: 3 }}
						color="error"
						variant="outlined"
						onClick={() => formik.setFieldValue('addresses.mailing', null)}
						disabled={formik.isSubmitting}
					>
						{t('buttons.remove_mailing_address', { ns: 'core' })}
					</Button>
				</>
			)}

			{/* TODO permissions check for Account flags? */}
			<AgencyUseOnlyDivider />

			<ClearFleetForm fields={agencyFields} form={formik} loading={formik.isSubmitting} />
		</Box>
	);
}
