import RouteStepper, { RouteStepperProps } from 'core/components/RouteStepper';
import TransmittalPaths from 'modules/transmittals/routes/paths';
import { useTranslation } from 'react-i18next';
import Program from 'types/Program';

export interface TransmittalStepsProps {
	program: Program;
	id?: number;
}

export default function TransmittalSteps({ program: programEnum, id }: TransmittalStepsProps) {
	const { t } = useTranslation('transmittals');
	const program = programEnum.replace(/^PROGRAM_/i, '');

	const basePath = TransmittalPaths.Program.Edit;
	const steps: RouteStepperProps['steps'] = [
		{
			label: t('steps.start.breadcrumb'),
			route: id ? basePath.Start.buildPath({ program, id }) : '',
			activeRoutes: [TransmittalPaths.Program.buildPath({ program })],
		},
		{
			label: t('steps.confirm.breadcrumb'),
			route: id ? basePath.Confirm.buildPath({ program, id }) : '',
		},
		{
			label: t(`steps.test.breadcrumb.${program}`),
			route: id ? basePath.Test.buildPath({ program, id }) : '',
		},
		{
			label: t(`steps.prod.breadcrumb.${program}`),
			route: id ? basePath.Production.buildPath({ program, id }) : '',
		},
		{
			label: t(`steps.complete.breadcrumb`),
			route: id ? basePath.Complete.buildPath({ program, id }) : '',
		},
	];

	return <RouteStepper steps={steps} />;
}
