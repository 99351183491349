import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import { UUIDFormat } from 'types/UUID';
import { string } from 'yup';

const AccountPaths = route(
	'accounts',
	{},
	{
		Create: route('create', {
			searchParams: {
				userKey: yup(string().matches(UUIDFormat)).defined(),
				accountKey: yup(string().matches(UUIDFormat)),
			},
		}),
	},
);

export default AccountPaths;
