import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import { number, string } from 'yup';

const TransmittalPaths = route(
	'transmittals',
	{},
	{
		Program: route(
			':program',
			{ params: { program: yup(string().oneOf(['IFTA', 'IRP'])).defined() } },
			{
				Edit: route(
					':id',
					{ params: { id: yup(number()).defined() } },
					{
						Start: route('start'),
						Confirm: route('confirm'),
						Test: route('test'),
						Production: route('production'),
						Complete: route('complete'),
					},
				),
			},
		),
	},
);

export default TransmittalPaths;
