import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from 'core/components/Dialog';
import FormDateField from 'core/components/FormDateField';
import { useAPI, useToast } from 'core/hooks';
import { dayjsFromObject } from 'core/services/intl';
import { useFormik } from 'formik';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import AddVehiclePaths from 'modules/irp/modules/supplements/modules/add_vehicle/routes/paths';
import Vehicle, { VehicleFeeCalculationDate, VehicleFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Date, { DateValidationSchema, DateValidations } from 'types/Date';
import * as Yup from 'yup';

export type EditVehiclesForm = {
	feeCalculationDate: Date | null;
	atLeastOneField?: string;
};

interface EditVehiclesDialogProps extends Pick<DialogProps, 'isOpen' | 'setIsOpen'> {
	vehicles: Vehicle[];
	onVehiclesEdited: () => void;
}

export default function EditVehiclesDialog({ vehicles, isOpen, setIsOpen, onVehiclesEdited }: EditVehiclesDialogProps) {
	// Hooks
	const { t } = useTranslation(['irp/supplements/add_vehicle']);
	const { openToast } = useToast();
	const supplementsService = useAPI(SupplementsService);
	const { supplementKey } = useTypedParams(AddVehiclePaths);

	// State
	const [isSaving, setIsSaving] = useState<boolean>(false);

	// Form validation
	const validationSchema = Yup.object()
		.shape({
			feeCalculationDate: DateValidationSchema.default(null)
				.test('futureDate', DateValidations.noFutureDate(t))
				.nullable(),
		})
		.test('required', (data, context) => {
			// At least one field is required
			if (data.feeCalculationDate) return true;

			return context.createError({
				path: 'atLeastOneField',
			});
		});

	// Form
	const { values, setFieldValue, ...formik } = useFormik<EditVehiclesForm>({
		initialValues: {
			feeCalculationDate: null,
		},
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			const updatedVehicles = vehicles.map((v) => {
				const { feeCalculationDate } = v.registration;
				if (!feeCalculationDate) return v;

				let fields: VehicleFields = {};

				// Set fee calculation date
				switch (feeCalculationDate.code) {
					case VehicleFeeCalculationDate.Purchase:
						fields = {
							purchase: {
								date: data.feeCalculationDate,
							},
						};
						break;
					case VehicleFeeCalculationDate.FirstOperated:
						fields = {
							registration: {
								firstOperatedDate: data.feeCalculationDate,
							},
						};
						break;
					case VehicleFeeCalculationDate.Lease:
						fields = {
							registration: {
								leaseDate: data.feeCalculationDate,
							},
						};
						break;
					case VehicleFeeCalculationDate.Other:
						fields = {
							registration: {
								otherDate: data.feeCalculationDate,
							},
						};
						break;
					default:
						break;
				}

				return supplementsService.updateVehicle(supplementKey, v.key, fields);
			});

			return Promise.all(updatedVehicles)
				.then(() => {
					formik.resetForm();
					onVehiclesEdited();
				})
				.finally(() => setIsSaving(false));
		},
	});

	const handleSubmit = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: `irp/supplements/add_vehicle/edit/incomplete`,
				message: t('dialogs.edit_vehicle.error', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleClose = () => {
		if (setIsOpen) setIsOpen(false);
		// Wait for dialog close
		setTimeout(() => formik.resetForm(), 250);
	};

	return (
		<Dialog
			title={t('dialogs.edit_vehicle.title', { ns: 'irp/supplements', count: vehicles.length })}
			isOpen={isOpen}
			onConfirm={handleSubmit}
			confirmLabel={t('buttons.save', { ns: 'core' })}
			setIsOpen={handleClose}
			maxWidth="sm"
		>
			<Typography paragraph>{t('dialogs.edit_vehicle.subheading', { ns: 'irp/supplements' })}</Typography>
			<form name="bulkEditVehicleForm" noValidate>
				<Grid container pt={1} spacing={2}>
					<Grid item xs={4}>
						<FormDateField
							name="feeCalculationDate"
							label={t('vehicle.registration.feeCalculationDate', { ns: 'data' })}
							helperText={formik.errors.feeCalculationDate}
							slotProps={{
								formControl: {
									error: !!formik.errors.feeCalculationDate || !!formik.errors.atLeastOneField,
									disabled: isSaving,
								},
								datePicker: {
									value: dayjsFromObject(values.feeCalculationDate),
									disableFuture: true,
									disabled: isSaving,
									onChange: (v) => {
										const year = v?.year();
										const month = (v?.month() || 0) + 1;
										const day = v?.date();

										setFieldValue('feeCalculationDate', year && month && day ? { year, month, day } : null, false);
									},
									slotProps: {
										field: {
											clearable: false,
										},
									},
								},
							}}
						/>
					</Grid>
				</Grid>
			</form>
		</Dialog>
	);
}
