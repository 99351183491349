import { CanAccess } from 'core/components';
import Title from 'core/components/Title';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import { HistorySearchType } from 'modules/history/shared/searchTypes';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Permissions from 'types/Permissions';
import HistoryPaths from './paths';

const History = lazy(() => import('./History'));
const HistoryDetail = lazy(() => import('./HistoryDetail'));
const HistoryDetailAccount = lazy(() => import('./HistoryDetailAccount'));
const HistoryDetailIfta = lazy(() => import('./HistoryDetailIfta'));

// Route component mappings
const HistoryRoutes = [
	{
		path: HistoryPaths.path,
		element: (
			<CanAccess resource={Permissions.History.resource} action={Actions.READ} showError>
				<Title title={(t) => `${t('title', { ns: 'history' })} - ${t('title', { ns: 'core' })}`} ns={['history']} />
				<Outlet />
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'history' }),
		},
		children: [
			{
				path: HistoryPaths.path,
				element: <History />,
				index: true,
			},
			{
				path: HistoryPaths.Account.path,
				element: <HistoryDetailAccount />,
				handle: {
					crumb: ({ params }) => `${HistorySearchType.Account} #${params.id}`,
				},
			} as Route<typeof HistoryPaths.Account>,
			{
				path: HistoryPaths.Fleet.path,
				element: <HistoryDetail searchType={HistorySearchType.Fleet} />,
			} as Route<typeof HistoryPaths.Fleet>,
			{
				path: HistoryPaths.IFTA.path,
				element: <HistoryDetailIfta />,
			} as Route<typeof HistoryPaths.IFTA>,
			{
				path: HistoryPaths.Vehicle.path,
				element: <HistoryDetail searchType={HistorySearchType.Vehicle} />,
			} as Route<typeof HistoryPaths.Vehicle>,
			{
				path: HistoryPaths.WeightGroup.path,
				element: <HistoryDetail searchType={HistorySearchType.WeightGroup} />,
			} as Route<typeof HistoryPaths.WeightGroup>,
		],
	} as Route<typeof HistoryPaths>,
];

export default HistoryRoutes;
