import { Namespace, TFunction } from 'i18next';
import * as Yup from 'yup';
import LookupValue from './LookupValue';
import { State, stateCountyRequired, StateFields } from './State';

export interface Address {
	type?: LookupValue;
	line1: string;
	line2?: string;
	county?: string;
	city: string;
	state: State | null;
	postalCode: string;
	country: string;
}

export interface AddressFields {
	line1: string;
	line2?: string;
	county?: string;
	city: string;
	state: StateFields;
	postalCode: string;
	// Country is inferred from state
}

export enum AddressType {
	// Mailing or shipping address
	Mailing = 'M',

	// Physical address, sometimes referred to as a business address
	Physical = 'P',
}

export enum PhysicalDeliveryType {
	MailingAddress = 'MailMailingAddress',
	BusinessAddress = 'MailBusinessAddress',
	OtherAddress = 'MailOtherAddress',
	PickupAddress = 'Pickup',
}

export const AddressValidationSchema = ({
	t,
	optionalCounty,
}: {
	t: TFunction<Namespace>;
	optionalCounty?: boolean;
}) => ({
	line1: Yup.string()
		.max(100, t('data.validation.len.max', { ns: 'core', len: 100 }))
		.required(t('data.validation.required', { ns: 'core' })),
	line2: Yup.string(),
	county: Yup.string()
		.max(50, t('data.validation.len.max', { ns: 'core', len: 50 }))
		.when('state', {
			is: (state: State | null) => stateCountyRequired(state?.code || '') && !optionalCounty,
			then: (schema) => schema.required(t('data.validation.required', { ns: 'core' })),
		}),
	city: Yup.string()
		.max(50, t('data.validation.len.max', { ns: 'core', len: 50 }))
		.required(t('data.validation.required', { ns: 'core' })),
	state: Yup.object<LookupValue>()
		.shape({
			id: Yup.number().required(t('data.validation.required', { ns: 'core' })),
		})
		.required(t('data.validation.required', { ns: 'core' })),
	postalCode: Yup.string()
		.matches(/^\d{5}(-\d{4})?$|^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, t('data.validation.zip', { ns: 'core' }))
		.transform((v) => v.toUpperCase())
		.required(t('data.validation.required', { ns: 'core' })),
});
