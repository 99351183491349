import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { DocumentType } from 'modules/documents/types/Document';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import VehicleDocumentationTable, {
	VehicleDocumentationTableProps,
	VehicleDocumentationTableType,
} from 'modules/irp/modules/supplements/components/VehicleDocumentationTable';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { useTranslation } from 'react-i18next';
import SupplementDocumentsUploader from './SupplementDocumentsUploader';

export interface SupplementDocumentsFormProps {
	previousPath: string;
	documentTypes: DocumentType[];
	tableType?: VehicleDocumentationTableType;
	validator?: VehicleDocumentationTableProps['validator'];
	onSubmit: () => void | Promise<void>;
}
/**
 * This component is a wrapper for VehicleDocumentationTable that includes
 * standard Card title and SupplementStepFooter;
 * Could be modified further to return a specific type of VDT
 */

export default function SupplementDocumentsForm({
	previousPath,
	onSubmit,
	documentTypes,
	validator,
	tableType = VehicleDocumentationTableType.Active,
}: SupplementDocumentsFormProps) {
	const { t } = useTranslation('irp/supplements');
	const { supplement } = useSupplement();

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent>
					<Typography variant="h3">{t('documentation.title')}</Typography>
					<VehicleDocumentationTable tableType={tableType} documentTypes={documentTypes} validator={validator} />

					<Divider sx={{ my: 2 }} />
					<SupplementDocumentsUploader supplementKey={supplement.key} documentTypes={documentTypes} />
				</CardContent>
			</Card>
			<SupplementStepFooter
				nextLabel={t('buttons.next', { ns: 'core' })}
				previousPath={previousPath}
				onNext={onSubmit}
			/>
		</Box>
	);
}
