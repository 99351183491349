import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { CanAccessProps } from 'core/components/CanAccess';
import { usePermissions } from 'core/hooks';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export interface AgencyUseOnlyDividerProps {
	permissions?: CanAccessProps[];
}

export default function AgencyUseOnlyDivider({ permissions, children }: PropsWithChildren<AgencyUseOnlyDividerProps>) {
	const { t } = useTranslation();
	const { canAccess } = usePermissions();

	// If the user does not have ANY of the permissions, display nothing
	// Otherwise, if the user has at least one of the permissions, display the children
	// NOTE: It is up to the children to check individual permissions, if needed
	if (permissions && !permissions.some((p) => canAccess(p.resource, p.action, p.primaryKey))) {
		return null;
	}

	return (
		<>
			<Divider sx={{ mt: 4, mb: 3 }}>
				<Chip label={t('user.type.use_only', { userType: t('user.type.jurisdiction') })} />
			</Divider>
			{children}
		</>
	);
}
