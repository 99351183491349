import { numberFormat } from 'core/services/intl';
import { SupplementType } from 'types/Supplement';
import Tracking from 'types/Tracking';

export const MaxDocumentSize = 25 * 1024 * 1024; // 25 MB

export default interface Document {
	id: string;
	name: string;
	contentType: string;
	size: number;
	type: {
		code: DocumentType;
	};
	tracking: Tracking;
	tags?: Record<string, string>;
}

export enum DocumentType {
	Form2290 = 'form2290',
	Title = 'title',
	Other = 'other',
	SelfCertification = 'selfCertification',
	ClaimForRefund = 'claimForRefund',
}

export const SupplementDocumentTypes: {
	[key: string]: DocumentType[];
} = {
	[SupplementType.AddVehicle]: [DocumentType.Form2290, DocumentType.Title, DocumentType.Other],
	[SupplementType.EditVehicle]: [DocumentType.Form2290, DocumentType.Title, DocumentType.Other],
	[SupplementType.TransferVehicle]: [DocumentType.Form2290, DocumentType.Title, DocumentType.Other],
	[SupplementType.DeleteVehicle]: [DocumentType.Other],
	[SupplementType.AddFleet]: [DocumentType.Form2290, DocumentType.Title, DocumentType.Other],
	[SupplementType.WeightGroupChange]: [DocumentType.Form2290, DocumentType.Other],
	[SupplementType.NewAccount]: [DocumentType.Form2290, DocumentType.Title, DocumentType.Other],
	[SupplementType.Renewal]: [DocumentType.Form2290, DocumentType.Title, DocumentType.Other],
};

export function documentSize(size: number, showDecimals = true): string {
	if (size < 1024) {
		return `${numberFormat(size)} B`;
	}
	if (size < 1024 * 1024) {
		return `${numberFormat(size / 1024, showDecimals ? 2 : 0)} KB`;
	}

	return `${numberFormat(size / (1024 * 1024), showDecimals ? 2 : 0)} MB`;
}
