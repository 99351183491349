import { route } from 'react-router-typesafe-routes/dom';

const EditVehiclePaths = route(
	'irp/supplements/:supplementKey/edit-vehicle',
	{},
	{
		Details: route('details'),
		Documentation: route('documentation'),
		Verify: route('verify'),
		Submit: route('submit'),
		Vehicle: route(
			'vehicles/:vehicleKey',
			{},
			{
				Edit: route(
					'edit',
					{},
					{
						Info: route('info'),
						WeightGroup: route('weight-group'),
						Registration: route('registration'),
					},
				),
				View: route(
					'view',
					{},
					{
						WeightGroup: route('weight-group'),
						Registration: route('registration'),
					},
				),
			},
		),
	},
);

export default EditVehiclePaths;
