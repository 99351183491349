import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import { string } from 'yup';

const NewAccountPaths = route(
	'irp/supplements/:supplementKey/new-account',
	{
		// Params needed to create the supplement
		searchParams: {
			userKey: yup(string()),
			accountKey: yup(string()),
		},
	},
	{
		Registration: route('registration'),
		Fleet: route(
			'fleet',
			// Index: Fleets details table
			{},
			{
				Details: route('details'),
				Jurisdictions: route('jurisdictions'),
				Info: route('info'), // Fleet info (read-only)
			},
		),
		WeightGroups: route(
			'weight-groups',
			{},
			{
				WeightGroup: route(
					':weightGroupKey',
					{},
					{
						Info: route('info'),
					},
				),
			},
		),
		Vehicles: route(
			'vehicles',
			{},
			{
				Vehicle: route(
					':vehicleKey',
					{},
					{
						Info: route('info'),
						WeightGroup: route('weight-group'),
						Registration: route('registration'),
						View: route(
							'view',
							{},
							{
								Credentials: route('credentials'),
								Purchase: route('purchase'),
								Documents: route('documents'),
								Registration: route('registration'),
							},
						),
					},
				),
			},
		),
		Documentation: route('documentation'),
		Verify: route('verify'), // TODO rename all verify routes to review
		Submit: route('submit'),
	},
);

export default NewAccountPaths;
