import { Module } from 'core/types/module';
import { Actions } from 'core/types/permissions';
import RazorPaths from 'modules/razor/paths';
import UserPaths from './modules/users/routes/paths';
import AdminRoutes from './routes';

const AdminModule: Module = {
	// ID of the module
	id: 'admin',

	// Navigation bar items
	nav: [
		{
			id: 'users',
			label: 'User Accounts',
			to: UserPaths.path,
			permission: { resource: 'admin.users', action: Actions.READ },
		},
		{
			id: 'inventory',
			label: 'Inventory',
			to: RazorPaths.Inventory.path,
		},
		{
			id: 'batch-jobs',
			label: 'Batch Jobs',
			to: RazorPaths.Correspondence.Print.path,
		},
	],

	// Permission
	permission: { resource: 'admin', action: Actions.READ },

	// Routes
	routes: AdminRoutes,
};

export default AdminModule;
